import { css, objectToStyles } from '../utils.js';
import chroma          from 'chroma-js';
import tinycolor from 'tinycolor2'

const themedStyles = css`
    /* BooleanSwitch */
/*    .ddk-container .daq-toggleswitch--light__background[color='#ABE2FB'], .ddk-container .daq-booleanswitch--light__background[color='#ABE2FB'] {
        background: ${props => props.theme.accent};
    } */

    .ddk-container .cmSQpo.daq-booleanswitch--light__background {
        background: ${props => props.theme.background_content};
    }

    .ddk-container .card .cmSQpo.daq-booleanswitch--light__background {
        background: ${props => props.theme.background_page};
    }

    .ddk-container .gJuplL.daq-booleanswitch--light__background,
    .ddk-container .card .gJuplL.daq-booleanswitch--light__background {
        background: ${props => props.theme.accent};
    }

    .ddk-container.ddk-container .dlqMgb.button.daq-booleanswitch--light__button:not(.btn):not([type="button"]) {
        border: 1px solid ${props => props.theme.border};
        background: ${props => props.theme.background_page};
    }

    .ddk-container.ddk-container .card .dlqMgb.button.daq-booleanswitch--light__button:not(.btn):not([type="button"]) {
        background: ${props => props.theme.background_content};
    }

    /* ColorPicker */

    .ddk-container .daq-colorpicker--light__container {
        ${ props => objectToStyles(props.theme.border_style) };
        border-color: ${props => props.theme.border};
    }

    .ddk-container .daq-colorpicker--light__container > div {
        border: none !important;
    }

    .ddk-container .daq-colorpicker--light__chromepicker input:first-of-type {
        box-shadow: none !important;
        ${ props => objectToStyles(props.theme.border_style, true) };
        border-color: ${props => props.theme.border} !important;
    }

    .ddk-container .chrome-picker svg,
    .ddk-container .chrome-picker svg path {
        fill: ${props => props.theme.text};
    }

    .ddk-container .chrome-picker span,
    .ddk-container .chrome-picker input {
        color: ${props => props.theme.text} !important;
    }

    /* Gauge */
    .ddk-container.ddk-container .cRfgdB .track.progress,
    .ddk-container.ddk-container .WeGnJ .track.progress {
        stroke: ${props => props.theme.accent};
    }

    .ddk-container circle.track:not(.progress) {
        stroke: ${props => props.theme.background_content};
    }
    .ddk-container .card circle.track:not(.progress) {
        stroke: ${props => props.theme.background_page};
    }

    .ddk-container .daq-gauge--light div.cAPXWV {
        color: ${props => props.theme.accent};
    }

    .ddk-container .daq-gauge--light .needle,
    .ddk-container .daq-gauge--light .needle-knob {
        stroke: ${props => props.theme.border};
    }

    .ddk-container .daq-gauge--light .needle-knob {
        fill: ${props => props.theme.background_content};
    }
    .ddk-container .card .daq-gauge--light .needle-knob {
        fill: ${props => props.theme.background_page};
    }

    .ddk-container .daq-gauge--light line.tick {
        stroke: ${props => props.theme.border};
    }

    .ddk-container .daq-gauge--light text {
        stroke: ${props => props.theme.text};
    }

    .ddk-container .daq-graduatedbar--light > div > div {
        background-color: ${props => props.theme.background_content};
    } 
    .ddk-container .card .daq-graduatedbar--light > div > div {
        background-color: ${props => props.theme.background_page};
    }

    /* Indicator */

    .ddk-container .daq-indicator--light__indicator--on.ckRPTu {
        background-color: ${props => props.theme.accent};
    }

    .ddk-container .daq-indicator--light__indicator--off.cktrNp {
        background-color: ${props => chroma(props.theme.accent).brighten()};
    }

    /* GraduatedBar */
    .ddk-container .daq-graduatedbar--light .gHlvEa {
        background-color: ${props => props.theme.accent};
    }

    /* Joystick */
    .ddk-container .daq-joystick--light__joystick .back {
        background-color: ${props => props.theme.text} !important;
    }
    .ddk-container .daq-joystick--light__joystick .front {
        background-color: ${props => 
            tinycolor(props.theme.text).isLight()
              ? tinycolor(props.theme.text).darken().toString()
              : tinycolor(props.theme.text).lighten().toString()
        } !important;
    }

    /* Knob */
    .ddk-container .knob circle.base, 
    .ddk-container .knob circle.indent {
        fill: ${props => props.theme.background_content};
        stroke: ${props => props.theme.border};
    }

    .ddk-container .card .knob circle.base, 
    .ddk-container .card .knob circle.indent {
        fill: ${props => props.theme.background_page};
    }

    .ddk-container .daq-knob--light line.tick {
        stroke: ${props => props.theme.border};
    }

    .ddk-container .track.progress{
        stroke: ${props => props.theme.accent};
    }


    .ddk-container .daq-knob--light text {
        fill: ${props => props.theme.text};
    }

    /* LED Display */
    
    .ddk-container .daq-leddisplay--light .fBGgGr path[fill^="hsla(0, 0%, 80%, 0.15"] {
        fill: ${props => chroma(props.theme.background_page).darken().alpha(0.15).css()};
    }

    .ddk-container .daq-leddisplay--light path[fill^="#ABE2FB"],
    .ddk-container .daq-leddisplay--light circle[fill^="#ABE2FB"],
    /* PowerButton */
    .ddk-container .daq-powerbutton--light path[fill^="#ABE2FB"] {
        fill: ${props => props.theme.accent};
    }

    .ddk-container .daq-leddisplay--light > div > div {
        ${props => objectToStyles(props.theme.border_style) };
        border-color: ${props => props.theme.border};
    }

    .ddk-container .gEhyzT.daq-powerbutton--light__indicator {
        background-color: ${props => props.theme.accent};
    }

    .ddk-container.ddk-container button.daq-powerbutton--light__button:not(.btn):not([type="button"]),
    .ddk-container.ddk-container button.daq-powerbutton--dark__button:not(.btn):not([type="button"]) {
        border-color: ${props => props.theme.border};
    }

    /* Numeric Input */
    .ddk-container .daq-numericinput--light input.daq-numericinput--light__input {
        background-color: transparent !important;
        color: ${props => props.theme.text} !important;
        ${props => objectToStyles(props.theme.border_style, true) };
        border-color: ${props => props.theme.border} !important;
    }

    .ddk-container .daq-numericinput--light b i {
        border-color: transparent transparent ${props => props.theme.text} !important;
    }

    .ddk-container .daq-numericinput--light b:nth-of-type(2) i {
        border-color: ${props => props.theme.text} transparent transparent !important;
    }

    /* PrecisionInput */
    .ddk-container .daq-precisioninput--light__output {
        ${props => objectToStyles(props.theme.border_style) };
        border-color: ${props => props.theme.border};
        background: ${props => props.theme.background_page};
    }

    .ddk-container .card .daq-precisioninput--light__output {
        background: ${props => props.theme.background_content};
    }

    .ddk-container .daq-precisioninput--light__output__digit {
        border-right: 1px solid ${props => props.theme.border};
        color: ${props => props.theme.text};
    }

    .ddk-container .daq-precisioninput--light__output__exp,
    .ddk-container .daq-precisioninput--light__output__expdigit {
        background: ${props => props.theme.background_content};
        color: ${props => props.theme.text};
    }

    .ddk-container .card .daq-precisioninput--light__output__exp,
    .ddk-container .card .daq-precisioninput--light__output__expdigit {
        background: ${props => props.theme.background_page};
    }

    /* Slider */
    .ddk-container .daq-slider--light .rc-slider-track[style^="background-color: rgb(135, 206, 235)"] {
        background-color: ${props => props.theme.accent} !important;
    }
/*    .ddk-container .daq-slider--light .rc-slider-dot {
 *        border-color: ${props => props.theme.accent} !important;
 *    }
 */
    .ddk-container .rc-slider-handle path{
        stroke: ${props => props.theme.accent}
    }

    .ddk-container .daq-slider--light .rc-slider-rail {
        background-color: ${props => props.theme.background_content};
    }
    .ddk-container .card .daq-slider--light .rc-slider-rail {
        background-color: ${props => props.theme.background_page};
    }

    .ddk-container .rc-slider-handle:active{
        box-shadow: 0px 0px 5px 0px ${props => props.theme.accent};
    }

    .ddk-container .rc-slider-handle path,
    .ddk-container .rc-slider-mark-text path {
        fill: ${props => props.theme.background_content};
    }

    .ddk-container .card .rc-slider-handle path,
    .ddk-container .card .rc-slider-mark-text path {
        fill: ${props => props.theme.background_page};
    }

    .ddk-container .rc-slider-mark-text > div[label] > div[label] {
        color: ${props => props.theme.text};
        background-color: ${props => props.theme.background_content};
        ${props => objectToStyles(props.theme.border_style, true) };
        border-color: ${props => props.theme.border};
    }

    .ddk-container .card .rc-slider-mark-text > div[label] > div[label] {
        background-color: ${props => props.theme.background_page};
    }

    .ddk-container .rc-slider-mark-text div[label] > div[label] {
        color: ${props => props.theme.text};
    }

    /* Stop Button */
    .ddk-container .daq-stopbutton--light button:not(.btn):not(.edit-theme-button):not([type="button"]),
    .ddk-container .daq-stopbutton--dark button:not(.btn):not(.edit-theme-button):not([type="button"]) {
        background-color: ${props => props.theme.accent_negative};
        border: ${props => props.theme.accent_negative};
        color: ${
            props => chroma(props.theme.accent_negative).luminance() > 0.5
              ? 'black'
              : 'white'
        };

    }

    /* Tank */

    .ddk-container .daq-tank--light__tick {
        color: ${props => props.theme.text};
    }

    .ddk-container .daq-tank--light__tick  .label{
        font-size: ${props => props.theme.font_size}
    }

    /* Thermometer */
    .ddk-container .daq-tank--light .dBcuVW.daq-tank--light__fill,
    .daq-thermometer--light .dHxrNJ.daq-thermometer--light__fill,
    .daq-thermometer--light .daq-thermometer--light__container .hLTLYz,
    .daq-thermometer--light .daq-thermometer--light__container .bJntrb{
        background: ${props => props.theme.accent};
    }

    .ddk-container .daq-thermometer--light__tick .tick {
        background: ${props => props.theme.border};
    }

    .ddk-container .daq-thermometer--light__tick .label {
        color: ${props => props.theme.text};
    }

    .ddk-container .eMfdXL.daq-toggleswitch--light__indicator--on {
        background-color: ${props => props.theme.accent};
    }

    .ddk-container .daq-toggleswitch--light__indicator--off {
        background-color: ${props => props.theme.background_content};
    }
    .ddk-container .card .daq-toggleswitch--light__indicator--off {
        background-color: ${props => props.theme.background_page};
    }

    .ddk-container .daq-thermometer--light__tick .label{
        font-size: ${props => props.theme.font_size}
    }

    /* Tank */

    .ddk-container .daq-tank--light__container {
        background: ${props => props.theme.background_content};
    }

    .ddk-container .card .daq-tank--light__container {
        background: ${props => props.theme.background_page};
    }

    /* Thermometer */

    .ddk-container .daq-thermometer--light__container {
        background: ${props => props.theme.background_content};
    }

    .ddk-container .card .daq-thermometer--light__container {
        background: ${props => props.theme.background_page};
    }

    /* ToggleSwitch */
    .ddk-container .daq-toggleswitch--light__background {
        background: ${props => props.theme.background_content};
    }
    .ddk-container .card .daq-toggleswitch--light__background {
        background: ${props => props.theme.background_page};
    }

    .ddk-container .igrnnx.button.daq-toggleswitch--light__button:not(.btn):not(.edit-theme-button):not([type="button"]) {
        border: 1px solid ${props => props.theme.accent};
        background: ${props => props.theme.background_page};
    }
    .ddk-container .card .igrnnx.button.daq-toggleswitch--light__button:not(.btn):not(.edit-theme-button):not([type="button"]) {
        border: 1px solid ${props => props.theme.accent};
        background: ${props => props.theme.background_content};
    }


`
export default themedStyles;
