/* global Plotly:true */
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import * as R                        from 'ramda';
import {edge as is_edge}             from 'is_js';
import chroma                        from 'chroma-js';
import tinycolor                     from 'tinycolor2'
import assignCssVariables            from '../assignCssVariables';
import Editor, {EditorWithPopout}    from '../ThemeEditor.react';
import {THEMES}                      from '../constants'
import {appendStyle, objectToStyles, extend} from '../utils';

/* These all export one const template literal as 'default' */
import style_slider               from '../css_js/var-rc-slider@6.1.2.css.js';
// for dash-core-components >= 0.46.0
import style_dates_v20            from '../css_js/var-react-dates@20.1.0.css.js';
// for dash-core-components <= 0.45.0
import style_dates_v12            from '../css_js/var-react-dates@12.3.0.css.js';
import style_select               from '../css_js/var-react-select@1.0.0-rc.3.css.js';
import style_virtualized_select   from '../css_js/var-react-virtualized-select@3.1.0.css.js';
import style_base                 from '../css_js/var-base.css.js';
import style_tabs                 from '../css_js/var-tabs.css.js';

import style_dashboard            from '../css_js/var-dashboard.css.js';
import style_bootstrap            from '../css_js/var-bootstrap.css.js';
import style_components           from '../css_js/var-components.css.js';
import style_table                from '../css_js/var-dash-table@4.5.1.css.js';
import style_edit_button          from '../css_js/var-edit-button.css.js';

import style_base_override        from '../css_js/override-base.css.js';
import style_select_override      from '../css_js/override-react-select@1.0.0-rc.3.css.js';
// for dash-core-components <= 0.45.0
import style_dates_override_v12   from '../css_js/override-react-dates@12.3.0.css.js';
// for dash-core-components >= 0.46.0
import style_dates_override_v20   from '../css_js/override-react-dates@20.1.0.css.js';
import style_virtualized_override from '../css_js/override-react-virtualized-select@3.1.0.css.js';
import style_slider_override      from '../css_js/override-rc-slider@6.1.2.css.js';
import style_daq                  from '../css_js/var-daq.css.js';
import style_table_override       from '../css_js/override-dash-table@4.5.1.css.js';

/* Dynamic Docs CSS */
import style_highlight_syntax from '../css_js/override-highlight-syntax.css.js'
import style_docs_templates_tabs from '../css_js/var-docs-templates-tabs.css.js'

/* Dev Tools CSS function */
import style_dev_tools from '../css_js/var-dev-tools.css.js'

/* Components CSS */
import MenuCss from '../css_js/Menu.css.js';
import PageCss from '../css_js/Page.css.js';

/* Helper Components */
import NotificationStore from '../_NotificationStore.react.js';

/*
 * event polyfill for IE
 * https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent
 */
function CustomEvent (event, params) {
    params = params || {
        bubbles: false,
        cancelable: false,
        detail: undefined
    };
    var evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(
        event, params.bubbles, params.cancelable, params.detail);
    return evt;
}
CustomEvent.prototype = window.Event.prototype;

const appendViewportTag = () => {
    var viewPortTag=document.createElement('meta');
    viewPortTag.id="viewport";
    viewPortTag.name = "viewport";
    viewPortTag.content = "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0";
    document.getElementsByTagName('head')[0].appendChild(viewPortTag);
}

/**
 * App provides Dash Design Kit styles
 * and an editable theme editor.
 * Wrap the layout of your application with `App`
 * and only use one `App` component per app.
 *
 * App will re-style and theme your app with its own
 * opinionated CSS. If you don't wish to use this CSS,
 * you may use functional components such as `ddk.FullScreen`,
 * `ddk.Modal`, and `ddk.Graph` without wrapping your app's layout
 * with `ddk.App`.
 */
class App extends Component {
    constructor(props) {
        super(props);
        // Default to the JS theme over the default theme
        const defaultTheme = R.defaultTo(window.theme || THEMES.light);
        // merge the THEMES.light template to prevent undefined theme vars
        var clonedThemeSchema = extend({}, THEMES.light);
        let extendedTheme = extend(clonedThemeSchema, defaultTheme(props.theme))

        this.state = {
            display_editor: false,
            theme: extendedTheme,
        };
        window.dashTheme = extendedTheme;
        this.themeStylesheets = this.themeStylesheets.bind(this);
        this.themeRoot = this.themeRoot.bind(this);
        this.updateTheme = this.updateTheme.bind(this);
    }

    // TODO: This should be more locally scoped e.g. to a "Row" React component
    themeRoot(theme) {
        // Set the app container to 100% of the browser window if embedded=False
        if (!this.props.embedded) {
            appendStyle('ddk-height', `
                body .ddk-container {
                    min-height: 100vh;
                }
            `);
        }
        appendStyle('ddk-global', `
            @media (max-width: ${theme.breakpoint_font}) {
                body .ddk-container:not(.ddk-container--print-context) {
                    font-size: ${theme.font_size_smaller_screen};
                }
            }

            body .ddk-container {
                color: ${theme.body_text};
                font-size: ${theme.font_size};
                background-color: ${theme.background_page};
                font-family: ${theme.font_family};
                margin: 0;
            }

            /*
             * low specificity for components rendered outside .ddk-container
             * intentionally lower than :root
             */
            html,
            /* ideal specificity */
            body .ddk-container {
                /* dump the theme variables as CSS variables */
                --accent-faded: ${chroma(theme.accent).alpha(0.5).css()};
                ${objectToStyles(assignCssVariables(theme))}
            }
          `);

    }

    updateTheme(newThemeAttrs) {
        var clonedTheme = {};
        extend(clonedTheme, this.state.theme);
        var newTheme = extend(clonedTheme, newThemeAttrs);
        this.setState({theme: newTheme});
        window.dashTheme = newTheme;
        window.dispatchEvent(new CustomEvent('dash-theme-update'));
    }

    themeStylesheets() {
        appendStyle('style_select_override', style_select_override(this.state))
        appendStyle('style_select', style_select(this.state))
        appendStyle('style_slider', style_slider(this.state))
        appendStyle('style_slider_override', style_slider_override(this.state))
        appendStyle('style_dates_v12', style_dates_v12(this.state))
        appendStyle('style_dates_v20', style_dates_v20(this.state))
        appendStyle('style_virtualized_select', style_virtualized_select(this.state))
        appendStyle('style_virtualized_override', style_virtualized_override(this.state))
        appendStyle('style_base', style_base(this.state))
        appendStyle('style_dates_override_v12', style_dates_override_v12(this.state))
        appendStyle('style_dates_override_v20', style_dates_override_v20(this.state))
        appendStyle('style_base_override', style_base_override(this.state))
        appendStyle('style_dashboard', style_dashboard(this.state))
        appendStyle('style_bootstrap', style_bootstrap(this.state))
        appendStyle('style_components', style_components(this.state))
        appendStyle('style_daq', style_daq(this.state))
        appendStyle('style_table', style_table(this.state))
        appendStyle('style_table_override', style_table_override(this.state))
        appendStyle('style_tabs', style_tabs(this.state))
        appendStyle('style_highlight_syntax', style_highlight_syntax(this.state))
        appendStyle('style_docs_templates_tabs', style_docs_templates_tabs(this.state))
        appendStyle('style_edit_button', style_edit_button(this.state))

        /* components */
        appendStyle('MenuCss', MenuCss(this.state))
        appendStyle('PageCss', PageCss(this.state))

        /* dev tools */
        if (document.getElementsByClassName('dash-debug-menu')[0]) {
            appendStyle('DevTools', style_dev_tools(this.state));
        }

    }

    UNSAFE_componentWillMount() {
        if (this.props.use_mobile_viewport) {
            appendViewportTag()
        }
    }

    componentDidMount() {

        // We can't do the following with CSS as the elements are not children of this (scoped) component */

        const undo_redo = document.getElementsByClassName('_dash-undo-redo')[0]
        // reposition undo_redo, as it will overlap the Edit Theme button
        if (this.props.show_undo_redo && undo_redo) {
            if (this.props.show_editor) {
                undo_redo.style.left = 'initial';
                undo_redo.style.right = '30px';
            }
        } else if (undo_redo) {
            undo_redo.style.display = 'none';
        }
        // normalize body margins
        if (document.body.style.margin === '') {
            document.body.style.margin = 0
        }

        this.themeStylesheets();
        this.themeRoot(this.state.theme);
    }

    componentDidUpdate(prevProps, prevState) {
        /*
         * short-circuit checks in order of perf hit (but also accuracy)
         * ramda.equals (deep comparison) will give the "true" result
         * as keys can be out-of-order in JSON.stringify
         * see https://measurethat.net/Benchmarks/Show/5205/0/ramda-equals-vs-jsonstringify
         */
        if ((this.state.theme !== prevState.theme)
            || (JSON.stringify(this.state.theme) !== JSON.stringify(prevState.theme))
            || !R.equals(this.state.theme, prevState.theme)) {

            this.themeStylesheets();
            this.themeRoot(this.state.theme);
        }
    }

    componentWillReceiveProps(nextProps) {
        if ((this.props.theme !== nextProps.theme)
            || (JSON.stringify(this.props.theme) !== JSON.stringify(nextProps.theme))
            || !R.equals(this.props.theme, nextProps.theme)) {

            this.updateTheme(nextProps.theme);
        }
    }

    render() {
        const {children, show_editor, notification_config} = this.props;
        const {theme, display_editor} = this.state;
        window.dashTheme = theme;

        return (
            <div
                id={this.props.id}
                style={this.props.style}
                className={
                    this.props.className
                     ? this.props.className + ' ddk-container'
                     : 'ddk-container'
                }
            >

                {show_editor ? (
                    <button
                        {...(!is_edge('<79')
                            ? {
                                onClick: () => this.setState({display_editor: !display_editor}),
                                className: 'edit-theme-button'
                              }
                            : {
                                className: 'edit-theme-button edit-theme-button--old-edge'
                              }
                        )}
                    >
                        {'Edit Theme'}
                    </button>
                ) : null}

                <NotificationStore
                    // TODO: NotificationStore ids might not be necessary in the end
                    id='my-notification-store'
                    {...notification_config}
                >
                    {children}
                </NotificationStore>

                {display_editor ? (
                    <EditorWithPopout
                        {...theme}

                        updateProps={themeUpdate => {
                            this.updateTheme(themeUpdate);
                        }}
                    />
                ) : null}

            </div>
        );
    }

}

App.defaultProps = {
    use_mobile_viewport: true,
    show_undo_redo: false,
    theme_dev_tools: true,
    embedded: false,
}

App.propTypes = {
    /**
     * The ID of this component, used to identify Dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    id: PropTypes.string,

    /**
     * The theme's configuration.
     * This configuration is editable with the theme editor.
     * The theme editor will also auto-generate these variables
     * for you in the "Copy & Save" tab.
     */
    theme: PropTypes.object,
    /**
     * The children of the app's main layout, wrapped in the `ddk.App` container
     */
    children: PropTypes.node,
    /**
     * Indicates whether "EDIT THEME" button in the bottom left corner should be visible on the App's page
     */
    show_editor: PropTypes.bool,
    /**
     * Theme the dev tools panel buttons with your DDK theme.
     */
    theme_dev_tools: PropTypes.bool,
    /**
     * Don't set opinionated styles that interfere with embedded apps
     * Right now, the only style rule removed with this flag set is 'min-height: 100vh'
     */
    embedded: PropTypes.bool,
    /**
     * Display the undo_redo button.
     */
    show_undo_redo: PropTypes.bool,

    notification_config: PropTypes.shape({
        /**
         * The time in milliseconds for which the NotificationStore will
         * remain onscreen. Default is 5*1000. -1 indicates no timeout.
         */
        timeout: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),

        /**
         * Whether or not Notifications in NotificationStore are displayed.
         */
        displayed: PropTypes.bool,

        /**
         * Default width of the NotificationStore.
         */
        width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

        /**
         * The border-radius in 'px' of the Notification container.
         */
        border_radius: PropTypes.string,

        /**
         * Array of booleans for filtering which types of array representing 'info', 'warn', 'danger'
         */
        type_display: PropTypes.arrayOf(PropTypes.bool),
    }),

    /**
     * Optimize for mobile viewports on
     * mobile.
     * If set to `False`, mobile devices will display in desktop mode.
     */
    use_mobile_viewport: PropTypes.bool,
    /**
     * Optional user-defined CSS class for the App container.
     */
    className: PropTypes.string,
    /**
     * Optional user-defined CSS styles for the App container.
     */
    style: PropTypes.object
}

export default App;
