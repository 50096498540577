import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {merge, mergeWith, propOr} from 'ramda';

import {ControlItemContext} from './ControlCard.react'

import Icon from './Icon.react';

import {relative_dimension} from './../utils.js';

const getControlStyle = (width, height, custom_padding, control_style_context) => {

    const {
        orientation,
        padding,
        wrap
    } = mergeWith((a, b) => b === undefined ? a : b, control_style_context, { padding: custom_padding })

    let controlStyle = {'padding':`${padding}px`}
    if (height) {
        controlStyle.height = relative_dimension(height);
    }
    if (orientation === 'vertical') {
        if (width) {
            controlStyle.maxWidth = relative_dimension(width);
        } else {
            controlStyle.width = relative_dimension(100);
        }
    }
    else {
        const flexBasis = !wrap ? 'auto' : relative_dimension(width);
        controlStyle.flexGrow = 1;
        controlStyle.flexShrink = 0;
        controlStyle.flexBasis = flexBasis;
        if (width) {
            controlStyle.maxWidth = relative_dimension(width);
        }
    }
    return controlStyle;
}

const getControlClass = (control_style_context, label_overrides) => {

    const {
        orientation,
        label_position,
        label_text_alignment,
    } = mergeWith((a, b) => b === undefined ? a : b, control_style_context, label_overrides)

    let control_class = 'control';
    let default_width;
    if (orientation === 'vertical') {
        control_class += ' control--vertical';
        default_width = 1;
    }

    // conditional class for label position
    if (label_position) {
        control_class += ' label--' + label_position
    }
    control_class += ' label--text--' + label_text_alignment

    return control_class;
}

/**
 * A container for DCC controls intended to be supplied as `children` to
 * the `ddk.ControlCard` component.
 *
 * **Example Usage**
 * ```
 * app.layout = ddk.App([
 *     ddk.ControlCard(
 *         children=[
 *               ddk.ControlItem(
 *                   dcc.Slider(
 *                       min=0,
 *                       max=10,
 *                       marks={
 *                           0: '0',
 *                           5: '5',
 *                           10: '10'
 *                       },
 *                       value=5
 *                   ),
 *                   label='Thrusters'
 *               ),
 *               ddk.ControlItem(
 *                   dcc.Input(
 *                       value=50,
 *                       type='number'
 *                   ),
 *                   label='Power'
 *               )
 *           ],
 *         orientation='horizontal',
 *         label_position='left',
 *     )
 *])
 * ```
 */
const ControlItem = (props) => {
    const {
        id,
        className,
        style,
        children,
        label,
        label_hover_text,
        label_icon_name,
        label_position,
        label_style,
        label_text_alignment,
        width,
        height,
        padding,
    } = props;

    return (
        <ControlItemContext.Consumer>
            { control_style_context => {
                const control_class = getControlClass(control_style_context, {label_position, label_text_alignment});
                const control_style = getControlStyle(width, height, padding, control_style_context)
                return (
                    <div
                        id={id}
                        className={`${control_class} ${propOr('', 'className', props)}`}
                        style={merge(style, control_style)}
                    >
                        {label && (<div
                            title={label_hover_text}
                            className="control--label"
                            style={merge(control_style_context.label_style, label_style)}
                        >
                            {label + ' '}
                            {props.label_icon_name && (<Icon
                                icon_name={props.label_icon_name}
                                title={label_hover_text}
                            />)}
                        </div>)}
                        <div className="control--item">{children}</div>
                    </div>
                )
            }}
        </ControlItemContext.Consumer>
    )
}

ControlItem.defaultProps = {
    width: null,
}

ControlItem.propTypes = {
    /**
     * The ID of this component, used to identify Dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    id: PropTypes.string,

    /**
     * These should be `dcc` control elements like `dcc.Dropdown`, `dcc.Input`, `dcc.DatePickerSingle` or `htmlButton`.
     */
    children: PropTypes.node,

    /**
     * The text label for an individual control.
     */
    label: PropTypes.string,

    /**
     * Text to display when you hover over the Control's label.
     */
    label_hover_text: PropTypes.string,

    /**
     * The name of the font-awesome icon to display beside the label.
     */
    label_icon_name: PropTypes.string,

    /**
     * The positon of the label with respect to the control. Overrides the global
     * position inherited from the ControlItem's ControlCard.
     */
    label_position: PropTypes.oneOf(['top', 'left', 'bottom', 'right']),

    /**
     * The horizontal label text alignment. Overrides the global
     * text alignment inherited from the ControlItem's ControlCard.
     */
    label_text_alignment: PropTypes.oneOf(['left', 'right', 'center']),

    /**
     * Optional additional label CSS styles. Overrides the global label
     * style inherited from the ControlItem's ControlCard.
     */
    label_style: PropTypes.object,

    /**
     * The padding of (i.e. whitespace around) each individual control. Takes `%` int or string `Npx, Nem`, etc. values.
     * Overrides the global item padding inherited from the ControlItem's ControlCard.
     */
    padding: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),

    /**
     * The width of the control. Takes percent int or string `Npx, Nem`, etc. values
     * between 0 and 100 representing the width of the component
     * with respect to its parent.
     *   - This is a percentage by default: `25` means take up 25 percent of the space.
     *   - Unless <1, in which it represents a decimal: 0.25 is the same as 25
     *
     * Note that these units are different than the CSS `style` units where
     * `style={'width': 25}` means _25 pixels_, not 25 percent.
     */
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),

    /**
     * The height of the control. Takes percent int or string `Npx, Nem`, etc. values.
     */
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),

    /**
     * Optional additional CSS styles.
     * - If `width`, `padding`, or `margin` are supplied within `style`,
     * then this will override the component-level `width`, `padding`, or `margin`.
     */
    style: PropTypes.object,

     /**
     * Additional user-supplied classNames for the control container
     */
    className: PropTypes.string
}

export default ControlItem;
