import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {contains, equals, has, is, omit, pick, propOr, type} from 'ramda';

function appendStyle(css, id) {
    const node = document.createElement('style');
    node.textContent = css;
    node.type = 'text/css';
    node.id = id;
    document.head.appendChild(node);
}

// see https://stackoverflow.com/questions/468881/
const REPORT_CSS = `
body * {
  visibility: hidden;
}
.dash-note-toolbar {
    display: none;
}
`

const inPrintContext = () => contains('print=true', window.location.search);

export const ReportContext = React.createContext();

/**
 * The parent component for describing a report.
 * The contents of this component should be a set of `ddk.Page` components.
 *
 * **Example Usage**
 * ```
 * app.layout = ddk.App([
 *     ddk.Report(display_page_numbers=True, children=[
 *         ddk.Page([
 *             html.H1('Quarterly Earnings'),
 *             ddk.Block(width=50, margin=5, children=[
 *                 ddk.Graph(figure=px.scatter(
 *                     ddk.datasets.bubble(),
 *                     x='x1', y='y1'
 *                 ))
 *             ]),
 *             ddk.Block(width=50, margin=5, children=[
 *                 ddk.Graph(figure=px.scatter(
 *                     ddk.datasets.bubble(),
 *                     x='x2', y='y2'
 *                 ))
 *             ]),
 *
 *             html.H2('Expected Returns'),
 *             ddk.Block(width=50, margin=5, children=[
 *                 ddk.Graph(figure=px.scatter(
 *                     ddk.datasets.bubble(),
 *                     x='x2', y='y2'
 *                 ))
 *             ]),
 *             ddk.Block(width=50, margin=5, children=[
 *                 ddk.Graph(figure=px.scatter(
 *                     ddk.datasets.bubble(),
 *                     x='x1', y='y1'
 *                 ))
 *             ]),
 *             ddk.PageFooter("Past Performance Is No Guarantee of Future Results."),
 *         ]),
 *     ])
 * ])
 * ```
 */
class Report extends Component {
    componentDidMount() {
        if (inPrintContext()) {
            appendStyle(REPORT_CSS, 'report-css')
        }
        if (document.getElementsByClassName('ddk-container')[0]) {
            document.getElementsByClassName('ddk-container')[0].classList.add('ddk-container--print-context')
        }
    }

    componentWillUnmount() {
        document.getElementsByClassName('ddk-container')[0].classList.remove('ddk-container--print-context');
    }

    render() {
        const {props} = this;
        let className = propOr('', 'className', props);
        className = `
            ddk-report
            ${className}
            ddk-report--size-${propOr('', 'size', props)}
            ${inPrintContext() ? 'ddk-report--print-context' : ''}
            ddk-report--orientation-${props.orientation}
        `;

        const mutatePage = (child, i) => {
            if (!(equals('Object', type(child)) && has('props', child))) {
                return;
            }
            const childLayout = child.props._dashprivate_layout;
            if(childLayout.type === 'Page') {
                childLayout.props._page_number = i + propOr(
                    1,
                    'page_number_start_from',
                    props
                );
                childLayout.props._report = pick([
                    'display_page_numbers',
                    'orientation',
                    'page_style',
                    'page_style_even',
                    'page_style_odd',
                    'page_margin',
                    'page_margin_even',
                    'page_margin_odd',
                    'size'
                ], props);
            }
        }

        if (equals('Array', type(propOr(null, 'children', props)))) {
            for(let i=0; i<props.children.length; i++) {
                mutatePage(props.children[i], i);
            }
        } else if (equals('Object', type(propOr(null, 'children', props)))) {
            mutatePage(props.children, 0);
        }

        return (
            <ReportContext.Provider
                value={{
                    'inReportContext': true,
                    suppress_layout_exceptions: props.suppress_layout_exceptions
                }}
            >
                <div
                    className={className}
                    style={props.style}
                    id={props.id}
                >
                    {props.children}
                </div>
            </ReportContext.Provider>
        );
    }
}


Report.propTypes = {
    /**
     * The list of components that are children of the PageFooter container.
     * These children should be of the type `ddk.Page`.
     */
    children: PropTypes.any,

    /**
     * Overrides the default (inline) styles for the this component.
     */
    style: PropTypes.object,

    /**
     * Overrides the default (inline) styles for all `ddk.Page` children
     * of this component.
     */
    page_style: PropTypes.object,

    /**
     * Overrides the default (inline) styles for all `ddk.Page` children
     * of this component with an even page number.
     */
    page_style_even: PropTypes.object,

    /**
     * Overrides the default (inline) styles for all `ddk.Page` children
     * of this component with an odd page number.
     */
    page_style_odd: PropTypes.object,

    /**
     * Indicates whether to display page numbers for all `ddk.Page children
     * of this component.
     */
    display_page_numbers: PropTypes.bool,

    /**
     * Optional user-defined CSS class for the Report container.
     */
    className: PropTypes.string,

    /**
     * The ID of this component, used to identify Dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    id: PropTypes.string,

    /**
     * The physical dimensions of the Page children of this Report container.
     */
    size: PropTypes.oneOf([
        'letter', 'legal', 'a4'
    ]),

    /**
     * The orientation of the Page children of this Report container.
     */
    orientation: PropTypes.oneOf([
        'vertical', 'horizontal'
    ]),

    /**
     * The number to begin indexing the page count of the Page children
     * of this Report container.
     */
    page_number_start_from: PropTypes.number,

    /**
     * Set the (left, right, top, bottom) margin dimensions
     * for all Page children of this Report container in units (`in`, `px`, `em`, etc.)
     */
    page_margin: PropTypes.exact({
        'left': PropTypes.string,
        'right': PropTypes.string,
        'top': PropTypes.string,
        'bottom': PropTypes.string,
    }),

    /**
     * Set the (left, right, top, bottom) margin dimensions
     * for all Page children of this Report container in units (`in`, `px`, `em`, etc.)
     * with an even page number.
     */
    page_margin_even: PropTypes.exact({
        'left': PropTypes.string,
        'right': PropTypes.string,
        'top': PropTypes.string,
        'bottom': PropTypes.string,
    }),

    /**
     * Set the (left, right, top, bottom) margin dimensions
     * for all Page children of this Report container in units (`in`, `px`, `em`, etc.)
     * with an odd page number.
     */
    page_margin_odd: PropTypes.exact({
        'left': PropTypes.string,
        'right': PropTypes.string,
        'top': PropTypes.string,
        'bottom': PropTypes.string,
    }),

    suppress_layout_exceptions: PropTypes.bool
}


Report.defaultProps = {
    size: 'letter',
    orientation: 'vertical',
    suppress_layout_exceptions: false
}


export default Report;
