/* IE polyfill for closest()
   from https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill
*/
export default function polyfillClosest() {
    if (!Element.prototype.matches) {
      Element.prototype.matches = Element.prototype.msMatchesSelector ||
                                  Element.prototype.webkitMatchesSelector;
    }

    if (!Element.prototype.closest) {
        Element.prototype.closest = function(s) {
            var el = this;

        do {
            if (el.matches(s)) return el;
                el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
            return null;
        };
    }
}
