import React, {Component} from 'react';
import PropTypes from 'prop-types';

export const NotificationContext = React.createContext();

import { NotificationStoreContext } from '../_NotificationStore.react'

export default class Notification extends Component {
    constructor(props) {
        super(props);
        const id = this.props.id ? this.props.id : Math.random().toString(36).substring(2);
        this.props.setProps({
            id: id
        });
        this.state = { called: false }
    }

    render() {
        return (
            <NotificationStoreContext.Consumer>
                {saveSelf => {
                    if (this.props.displayed && this.props.id && saveSelf) {
                        if (!this.state.called) {
                            saveSelf({...this.props});
                            this.setState({called: true});
                        }
                    }
                    return null
                }}
            </NotificationStoreContext.Consumer>
        )
    }
}

Notification.defaultProps = {
    type: 'info',
    user_dismiss: true,
    collapsed: false,
    displayed: true,
}

Notification.propTypes = {
    /**
     * The ID of this component, used to identify Dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    id: PropTypes.string,

    /**
     * The list of components that are children of the Notification.
     */
    children: PropTypes.node,

    /**
     * The time in milliseconds for which the Notification will
     * remain onscreen. Default is 5*1000. -1 indicates no timeout.  
     */
    timeout: PropTypes.number,

    /**
     * Whether or not a notification is displayed.
     */
    displayed: PropTypes.bool,

    /** 
     * Whether or not a notification is collapsed. 
    */
    collapsed: PropTypes.bool,

    /**
     * Applies an 'x' to the top right-hand corner 
     * of the Notification that dismisses it on click.
     */
    user_dismiss: PropTypes.bool,

    /**
     * Assigns a type which applies styling and can be
     * used to filter. 
     */
    type: PropTypes.oneOf([
        'info', 'warn', 'danger'
    ]),

    /**
     * The title of the Notification. 
     */
    title: PropTypes.string,

    /**
     * Optional user-defined CSS class for the Notification
     */
    className: PropTypes.string,

    /**
     * Optional additional CSS styles.
     * - If `width`, `padding`, or `margin` are supplied within `style`,
     * then this will override the component-level `width`, `padding`, or `margin`.
     */
    style: PropTypes.object,

    /**
     * A Dash assigned function for deleting the notification. 
     */
    delete: PropTypes.func,

    /**
     * Dash-assigned callback that gets to set an id.
     */
    setProps: PropTypes.func,
} 
