import {css} from '../utils';
import chroma from 'chroma-js';

// Super specific styles to override .ddk-container.ddk-container h1
const themedStyles = css`
    .ddk-container .ddk-page {
        background-color: ${props => props.theme.report_background_page};
        color:  ${props => props.theme.report_text};
        font-family: ${props => props.theme.report_font_family};
    }
    .ddk-container .ddk-page h1,
    .ddk-container .ddk-page h2,
    .ddk-container .ddk-page h3,
    .ddk-container .ddk-page h4,
    .ddk-container .ddk-page h5,
    .ddk-container .ddk-page h6 {
        font-family: ${props => props.theme.report_font_family};
        color:  ${props => props.theme.report_text};
    }
    .ddk-container .ddk-page.ddk-page {
        font-size: ${props => props.theme.report_font_size};
    }
    .ddk-container.ddk-container.ddk-container--print-context .ddk-report .layout-sidebar .layout-title .title {
        font-family: ${props => props.theme.report_font_family};
        font-size: ${props => props.theme.report_font_size};
    }
    /* CSS black magic from Lea Verou (background-attachment-local) */
    /* only show overflow warning when debug=True */
    #_dash-global-error-container .ddk-report:not(.ddk-report--print-context) .ddk-page--inner {
        /* overflow warning */
        background: ${props =>
            `
            /* Shadow covers */
            linear-gradient(${props.theme.report_background_page} 30%, rgba(255,255,255,0)),
            linear-gradient(rgba(255,255,255,0), ${props.theme.report_background_page} 70%) 0 100%,

            /* Shadows */
            radial-gradient(50% 0, farthest-side, ${chroma(props.theme.accent_negative).alpha(0.5).css()}, rgba(0,0,0,0)),
            radial-gradient(50% 100%,farthest-side, ${chroma(props.theme.accent_negative).alpha(0.5).css()}, rgba(0,0,0,0)) 0 100%`};
        background: ${props => `
            /* Shadow covers */
            linear-gradient(${props.theme.report_background_page} 30%, rgba(255,255,255,0)),
            linear-gradient(rgba(255,255,255,0), ${props.theme.report_background_page} 70%) 0 100%,

            /* Shadows */
            radial-gradient(farthest-side at 50% 0, ${chroma(props.theme.accent_negative).alpha(0.5).css()}, rgba(0,0,0,0)),
            radial-gradient(farthest-side at 50% 100%, ${chroma(props.theme.accent_negative).alpha(0.5).css()}, rgba(0,0,0,0)) 0 100%
        `};
        background-repeat: no-repeat;
        background-color: transparent;
        background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;

        /* Opera doesn't support this in the shorthand */
        background-attachment: local, local, scroll, scroll;
    }
}
`
export default themedStyles;
