import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {merge, propOr} from 'ramda';

import {relative_dimension, defaultToTheme} from './../utils.js';

/**
 * A layout component used for easily arranging groups of elements.
 *
 * **Example Usage**
 * ```
 * app.layout = ddk.App([
 *     ddk.Block(
 *         width=30,
 *         children=[
 *             # this content takes up 30% of the screen's width
 *         ]
 *     ),
 *     ddk.Block(
 *         width=70,
 *         children=[
 *             # this content takes up 70% of the screen's width
 *         ]
 *     ),
 *     ddk.Block(
 *         width=50,
 *         children=[
 *              # this content wraps onto the next line
 *              # (below the blocks above) and takes up 50%
 *              # of the screen's width
 *         ]
 *     )
 * ])
 * ```
 */
function Block(props){
    const {children, id, padding, margin} = props;

    let width = props.width;
    if (width < 1) {
        width *= 100.
    }
    const style = merge(
        {
            padding: padding,
            margin: margin,
            width : relative_dimension(parseFloat(width, 10), parseFloat(defaultToTheme(margin, 'card_margin'), 10))
        },
        propOr({}, 'style', props)
    );

    return (
        <div
            style={style}
            data-user-style={JSON.stringify(props.dataUserStyle || props.style)}
            className={`block ${propOr('', 'className', props)}`}
            id={id}
        >
            {children}
        </div>
    )
}

Block.defaultProps = {
    padding: 0,
    margin: 0,
    width: 100
}

Block.propTypes = {
    /**
     * The ID of this component, used to identify Dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    id: PropTypes.string,

    /**
     * The list of components that are children of the Block container.
     */
    children: PropTypes.node,

    /**
     * Number between 0 and 100 representing the width of the component
     * with respect to its parent.
     * - This is a percentage by default: `25` means take up 25% of the space.
     * - Unless <1, in which it represents a decimal: 0.25 is the same as 25
     *
     * Note that these units are different than the CSS `style` units where
     * `style={'width': 25}` means _25 pixels_, not 25%.
     */
    width: PropTypes.number,

    /**
     * Space (in pixels) surrounding the block.
     */
    margin: PropTypes.number,

    /**
     * Space (in pixels) on the inside of the block, between the border
     * and the edge of the content.
     */
    padding: PropTypes.number,

    /**
     * Optional additional CSS styles.
     * - If `width`, `padding`, or `margin` are supplied within `style`,
     * then this will override the component-level `width`, `padding`, or `margin`.
     */
    style: PropTypes.object,

    /**
     * Optional user-defined CSS class for the Block container.
     */
    className: PropTypes.string
}

export default Block;
