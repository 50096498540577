// event resize shim for IE
export default () => {
    if (typeof Event === 'function') {
        setTimeout(() => { window.dispatchEvent(new Event('resize')); }, 1);
    } else { // IE API
        const element = document.documentElement;
        const event = document.createEvent('CustomEvent');
        event.initCustomEvent('onresize', false, false, undefined);
        setTimeout(() => { element.dispatchEvent(event); }, 1);
    }
};