import React, { Component } from 'react';
import { DEFAULT_SCALE } from './constants.js';
import { CUSTOMIZED_COLORPICKER_COLORS } from '../constants.js'
import * as R from 'ramda';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { GithubPicker } from 'react-color';
import chroma from 'chroma-js';
 
import { isValidColor } from '../utils.js'

import icon_circle_x from '../icons/icon-circle-x.svg'

const getItems = colorscale =>
  colorscale.map((v, k) => ({
    id: `item-${k}`,
    color: v,
  }));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const replace = (list, item, index) => {
    const result = Array.from(list);
    result.splice(index, 1, item);
    return result;
}

// Not used at the moment (for dragging outside to remove)
const remove = (list, index) => {
    const result = Array.from(list);
    result.splice(index, 1);
    return result;
}

const getItemStyle = (isDragging, draggableStyle, color, len) => ({
  userSelect: 'none',
  height: '20px',

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : color,
  border: isDragging ? '2px dotted black' : 'none',

  // styles we need to apply on draggables
  ...draggableStyle,
  cursor: 'n-resize'
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'black',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
});

class DraggableSwatchInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            color: this.props.defaultColor,
            validColor: isValidColor(this.props.defaultColor)
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        this.setState({ color: e.target.value, validColor: isValidColor(e.target.value) });
    }

    handleSubmit(e) {
        // we clicked on a swatch, so don't submit the text input's value
        if (e && e.relatedTarget && e.relatedTarget.hasAttribute('tabIndex') && e.relatedTarget.hasAttribute('title')) {
            e.preventDefault();
            return;
        }
        const {scaleArr, onSelectSwatch, index} = this.props;
        if (this.state.color && this.state.validColor) {
            onSelectSwatch(scaleArr, this.state.color, index);
        }
    }

    render() {
        const {scaleArr, onSelectSwatch, index} = this.props
        return (
          <input
            style={{
                backgroundImage: !this.state.validColor
                    ? 'url(' + icon_circle_x + ')'
                    : 'initial',
                backgroundColor: this.state.validColor
                    ? this.state.color
                    : 'initial',
                color: this.state.validColor && chroma(this.state.color).luminance() < 0.5 ? 'white' : 'black'
            }}
            className={`DraggableSwatch--input ${(!this.state.validColor ? ' invalid-input' : '')}`}
            type="text"
            value={this.state.color}
            onClick={ e => { e.target.select(); this.props.toggleSwatchPicker(); } }
            onChange={this.handleChange}
            onBlur={this.handleSubmit}
            onKeyDown = {
                (e) => e.key === 'Enter' && this.handleSubmit()
            }
          />
        )
    }
}

export default class DraggableColorscale extends Component {
  constructor(props) {
    super(props);

    this.toggleSwatchPicker = this.toggleSwatchPicker.bind(this);
    this.onSelectSwatch = this.onSelectSwatch.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.clickSwatch = this.clickSwatch.bind(this);
    this.outsideClickListener = this.outsideClickListener.bind(this);

    this.state = {
      showSwatchPicker: false,
    };
  }

  componentDidMount() {
    const editorDocument = this.props.popoutWindow.document;
    editorDocument.addEventListener('click', this.outsideClickListener)
  }

  componentWillUnmount() {
    const editorDocument = this.props.popoutWindow.document;
    editorDocument.removeEventListener('click', this.outsideClickListener)
  }

  outsideClickListener(e) {
      if (this.state.showSwatchPicker
          && e
          && e.target
          // loose check for colorpicker swatch square or text input
          && !e.target.hasAttribute('tabIndex')
          && !e.target.classList.contains('DraggableSwatch--input')
          && !e.target.hasAttribute('title')) {
              this.toggleSwatchPicker(this.state.swatchIndex);
      }
  }

  toggleSwatchPicker(i) {
    this.setState({ 
        showSwatchPicker: !this.state.showSwatchPicker,
        swatchIndex: i
    });
  };

  onSelectSwatch(colors, color, swatchIndex) {
    // TODO: DRY
    const editorDocument = this.props.popoutWindow.document;
    editorDocument.body.classList.add('waiting');
    // force reflow
    void(editorDocument.body.offsetHeight);
    this.toggleSwatchPicker(this.state.swatchIndex)
    setTimeout(() => {
        const colorscale = replace(colors, color, swatchIndex);
        this.props.onClick(colorscale, this.props.start, this.props.rot);
        editorDocument.body.classList.remove('waiting');
    }, 0);
  }

  clickSwatch(color) {
    this.onSelectSwatch(this.props.colorscale, color.hex, this.state.swatchIndex);
  }

  onDragEnd(result) {
    // TODO: DRY
    const editorDocument = this.props.popoutWindow.document;
    editorDocument.body.classList.add('waiting');
    // force reflow
    void(editorDocument.body.offsetHeight);
    setTimeout(() => {
        let items = [];
        // dropped outside the list
        if (!result.destination) {
            editorDocument.body.classList.remove('waiting');
            return;
        } else {
            items = reorder(
              this.props.colorscale,
              result.source.index,
              result.destination.index
            );

            this.props.onClick(items, this.props.start, this.props.rot);
        }
        editorDocument.body.classList.remove('waiting');
    }, 0);
  }

  render() {
    const scale = this.props.colorscale
      ? getItems(this.props.colorscale) : DEFAULT_SCALE;
    const scaleArr = this.props.colorscale

    return (
      <div className="dragDropSwatches">
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable" direction="vertical">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  {...provided.droppableProps}
                >
                  {scale.map((item, index) => (
                    <Draggable key={item.id} color={item.color} draggableId={item.id} index={index}>
                      {(provided, snapshot) => (
                        <div>
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                                item.color,
                                scale.length
                              )}
                              onClick={() => this.toggleSwatchPicker(index)}
                            >
                              {item.content}
                              <DraggableSwatchInput
                                  defaultColor={item.color}
                                  onSelectSwatch={this.onSelectSwatch}
                                  scaleArr={this.props.colorscale}
                                  key={item.color}
                                  index={index}
                                  toggleSwatchPicker={this.toggleSwatchPicker.bind(this, this.state.swatchIndex)}
                              />
                            </div>
                            {provided.placeholder}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div
              className="dragDropPicker"
          >
              {this.state.showSwatchPicker && (
                <GithubPicker
                    color={ scaleArr[this.state.swatchIndex] }
                    triangle='hide'
                    colors={[scaleArr[this.state.swatchIndex]].concat(CUSTOMIZED_COLORPICKER_COLORS)}
                    onChangeComplete={this.clickSwatch}
                />
              )}
            </div>
          </div>
    );
  }
}
