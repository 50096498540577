import { css } from '../../utils.js';

const styles = css`
    html {
        box-sizing: border-box;
    }
    *, *:before, *:after {
        box-sizing: inherit;
    }

    .editor {
        font-size: 16px;
    }

    .editor h1, .editor h2, .editor h3, .editor h4, .editor h5, .editor h6 {
      margin-top: 0;
      margin-bottom: 0;
      font-weight: 300;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .editor p {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .app {
        margin-top: 10vh;
        margin-left: 10vw;
        background-color: rgb(245, 245, 245);
        padding: 50px;
        border-radius: 5px;
    }

    .ColorTab {
        flex: 0 1 auto;
        padding: 10px;
        margin: 20px;
        border: 1px solid #d8d8d8;
    }

    .ColorTab--panel {
        height: 7vw;
        width: 10vw;
        margin: 0 auto;
    }

    @media only screen and (min-width: 600px) and (max-width: 1000px) {
        .ColorTab--panel {
            height: 10vw;
            width: 14vw;
        }
        .ColorTab--labelContainer {
            max-width: 14vw;
        }
    }

    @media only screen and (max-width: 600px) {
        .ColorTab {
            margin: 10px;
            width: calc(50% - 20px); /* 20px = margin * 2 */
        }
    }

    .ColorTab--labelContainer {
        max-width: 10vw;
    }

    @media only screen and (max-width: 600px) {
        .ColorTab--panel, .PresetTab {
            width: auto;
            margin: 0 auto;
        }

        .ColorTab--Panel {
            height: 15vh;
        }

        .ColorTab--labelContainer {
            max-width: initial;
        }
    }

    .ColorTab--label {
        text-align: left;
        font-size: 16px;
        text-overflow: ellipsis;
        line-height: 18px;
        color: #848484;
        padding: 5px;
        padding-bottom: 0px;
    }

    .Colortab--label.ColorTab--title {
        color: #595959;
        text-align: left;
        font-size: 13px;
    }

    .Colortab--label.ColorTab--footer input {
        color: rgb(80, 103, 132); /* dark greyish-blue */
    }

    .ColorTab--input {
        background-color: transparent;
        border: none;
        font-family: 'Inconsolata', monospace, serif;
        font-size: 14px;
        text-align: left;
        max-width: 100%;
        border: 1px solid #d8d8d8;
        padding: 5px;
    }

    .ColorTab--input:focus {
        outline: none;
        border: 1px solid #7544ea;
    }

    .ColorTab--strip {
        width: 100%;
    }
    .Settings {
        display: inline-block;
        float: left;
        width: 100%;
    }

    .Settings--title-container {
        width: 100%;
        padding: 15px;
    }

    .Settings--title {
        color: #000000;
        line-height: 30px;
        font-size: ;
    }

    .Settings--title.Settings--subtitle {
        font-size: 13px;
    }

    .cf,
    .Settings:after {
        content: "";
        display: table;
        clear: both;
    }

    .cf, .Settings--title-container .Settings:after {
        display: flex;
    }

    .ColorTab--editor {
        height: 100%;
        width: 34%;
        margin-left: 66%;
        background-color: white;
        z-index: 0;
        position: relative;
        top: 0;
    }

    .ColorRoll {
        width: 100%;
        height: 100%;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    .ColorTab--editor {
         transition: opacity 300ms;
         opacity: 1;
    }

    .ColorTab--editor.hidden {
        opacity: 0;
    }

    .ColorRoll--slice {
        height: 30px;
        border-top: thin lightgrey solid;
        border-left: thin lightgrey solid;
        cursor: pointer;
    }

    .Select-value-label .ColorWayPicker--option {
        padding-right: 15px; /* make room for dropdown arrow */
    }

    .ColorWayPicker--option {
        height: 100%;
        padding: 5px;
    }

    .ColorWayPicker--option-item {
        display: inline-block;
        width: 20%;
        height: 27px;
    }
    .ColorWayPicker--option-item:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .ColorWayPicker--option-item:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .ColorWayPicker--container {
        width: 230px;
    }

    .ColorWayPicker--container .Select-control {
        height: 40px;
    }

    .BordersFonts--container {
        float: left;
        padding-left: 20px;
        clear: both;
        width: 100%;
        margin-bottom: 30px;
    }

    .BorderPicker--container {
        display: flex;
        align-items: center;
        width: 60%;
    }

    @media only screen and (max-width: 800px) {
        .BorderPicker--container {
            width: 100%;
        }
    }

    @media only screen and (max-width: 600px) {
        .borderPicker--container, .shadowPicker--container {
            flex-direction: row;
            align-items: flex-start;
        }
    }

    .Settings--container {
        color: #000000;
        background-color: #ffffff;
        font-family: "Open Sans", Arial, sans-serif;
        display: flex;
        flex-flow: column;
        align-items: center;
        min-height: 100%;
        padding-bottom: 350px;
        overflow-x: hidden;
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: contain;
    }

    .Settings--container .tab-parent {
        width: 100%;
    }

    .Settings--container .tab-container {
        user-select: none;
    }

    .Settings--container input[type=checkbox],
    .Settings--container input[type=radio]   {
        border: 1px solid black;
        color: black;
        width: 20px;
        height: 20px;
        outline: 0;
        padding: 0;
        float: left;
        -webkit-appearance: none;
        -moz-appearance: none;
        margin-right: 5px;
        cursor: pointer
    }

    .Settings--container input[type=checkbox]:checked:before {
        content: "✔";
        float: left;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
    }

    .Settings--container button {
        color: black;
        background-color: white;
        border-color: black;
        padding: 0px 10px;
        margin: 5px;
        cursor: pointer;
    }

    .Settings--container label {
        cursor: pointer;
    }

    .Settings--container .accentOptions {
        display: flex;
        line-height: 26px;
    }

    .ColorScalePicker, .ColorWayPicker {
        min-width: 40%;
        max-width: 50%;
        background-color: white;
    }

    .GraphColorPickers--container {
        display: flex;
        justify-content: center;
    }

    .GraphColorPickers--container .Colorscale,
    .GraphColorPickers--container .Colorway {
        display: flex;
        justify-content: center;
    }

    .ColorscalePicker .colorscalePickerTopContainer,
    .ColorwayPicker .colorscalePickerTopContainer,
    .CustomColorPicker .colorscalePickerTopContainer {
        /* Bug in ColorscalePicker where fixed colorScaleType
           disables colorscale type selection but still shows Select menu */
           display: none;
    }

    .ColorscalePicker .Settings--title,
    .ColorwayPicker .Settings--title {
        height: auto;
        display: inline-block;
    }

    @media only screen and (max-width: 600px) {
        .ColorscalePicker .Settings--title,
        .ColorwayPicker .Settings--title {
            display: block;
        }
    }

    .ColorscalePicker li.toggleTab,
    .ColorwayPicker li.toggleTab {
        padding: 5px;
        margin-left: 2px;
        margin-right: 2px;
        cursor: pointer;
        list-style: none;
        outline: none;
        border: 1px solid rgb(80, 103, 132);
        border-bottom: none;
    }

    .ColorscalePicker ul,
    .ColorwayPicker ul {
        display: flex;
    }

    .ColorscalePicker li.toggleTab:focus,
    .ColorwayPicker li.toggleTab:focus {
        border-right: 1px solid #003366;
        border-bottom: 3px solid #003366;
    }

    .colorscaleDescription, .ColorscaleControlPanel {
        display: none;
    }

    .colorsContainer {
        display: flex;
        flex-wrap: wrap;
    }

    @media only screen and (max-width: 800px) {
        .colorsContainer {
            justify-content: center;
        }
    }

    .pageAccentTabContainer .ColorTab .ColorTab--panel {
        width: 24%;
        height: 100%;
        float: left;
    }

    .pageAccentTabContainer .ColorTab--labelContainer {
        float: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
    }

    .ColorScalePicker, .ColorWayPicker {
        margin: 15px 30px;
        padding: 20px;
        border: 1px solid #d8d8d8;
    }

    .colorscaleLabel {
        font-weight: 600;
        font-size: 12px;
        color: #2a3f5f;
        display: inline-block;
        width: 25%;
        text-align: start;
    }

    .colorscale-block {
        padding: 10px 0px;
    }

    .colorscale-block, .colorscaleLabel {
        padding-right: 5px;
        padding-left: 5px;
    }

    .typographyTab {
        display: flex;
        flex-direction: column;
    }

    .fontPreview--body {
        display: flex;
    }

    .fontPreview--body div {
        padding: 25px;
        text-align: justify;
    }

    .fontSelect {
        width: 100%;
        display: flex;
        align-items: center;
        margin: 40px 0px;
    }

    @media only screen and (max-width: 800px) {
        .fontSelect {
            width: 100%;
        }
    }

    @media only screen and (max-width: 600px) {
        .fontSelect {
            flex-direction: column;
            align-items: flex-start;
        }
        .Select {
            width: 70%;
            padding-bottom: 10px;
        }
    }

    @media only screen and (max-width: 600px) {
        .fontSelect {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .Settings--title--container {
        display: flex;
        align-items: center;
    }

    .HeaderAlignmentPicker {
        width: 300px;
    }

    @media only screen and (max-width: 600px) {
        .Settings--title--container {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .fontSelect .FontPicker,
    .fontSelect .FontSizePicker,
    .fontSelect .FontColorPicker {
        flex: 1;
        margin: 0px 20px;
    }

    .Capitalization--container {
        display: flex;
    }

    .Capitalization--container .fontSelect {
        width: 30%;
        margin: 40px 20px;
    }

    .Capitalization--container .fontSelect:first-child {
        margin-left: 0px;
    }

    @media only screen and (max-width: 600px) {
        .fontSelect .FontPicker,
        .fontSelect .FontSizePicker,
        .fontSelect .TitleCapitalizationPicker,
        .fontSelect .FontColorPicker {
            margin-left: 0;
            width: 50%;
        }
    }

    @media only screen and (min-width: 600px)  {
        .fontSelect .FontPicker,
        .fontSelect .FontSizePicker,
        .fontSelect .FontColorPicker {
            max-width: 25%;
        }

        .fontSelect .TitleCapitalizationPicker,
        .fontSelect .ButtonCapitalizationPicker {
            max-width: 100%;
            width: 100%;
        }
    }

    .fontPreview {
        flex: 3;
        border: 1px solid #d8d8d8;
        background-color: white;
        display: flex;
    }

    @media only screen and (max-width: 800px) {
        .fontPreview {
            flex-direction: column;
        }
    }

    .fontPreview--characters, .fontPreview--sizes {
        flex: 1;
        padding: 40px;
    }

    @media only screen and (max-width: 800px) {
        .fontPreview--characters, .fontPreview--sizes {
            padding: 10px 40px;
        }
    }

    .fontPreview--sizes h1, .fontPreview--sizes h2 {
        display: inline;
    }


    .fontPreview--italic {
        font-style: italic;
    }

    .fontPreview--bold {
        font-weight: bold;
    }

    .swatchController {
        display: flex;
        padding: 10px;
        padding-bottom: 0px;
        padding-right: 0px;
    }

    .swatchController .swatchButtons {
        display: flex;
    }

    .swatchSlider {
        flex: 3;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .swatchButtons {
        flex: 1;
        justify-content: center;
    }

    .dragDropSwatches {
        position: relative;
    }

    .dragDropPicker {
        position: absolute;
        top: 0px;
        left: 100%;
    }

    .DraggableSwatch--input {
        float: right;
        width: 25%;
        text-align: center;
        border: none;
        text-overflow: ellipsis;
    }

    .Settings--container .tab-content {
        margin: 0 auto;
        width: 75%;
    }

    @media only screen and (max-width: 850px) {
        .Settings--container .tab-content {
            width: 90%;
        }
    }

    .ColorWayPicker .tab-content {
        width: 100%;
    }

    .fontSizePicker {
        margin: 0 30%
        margin-left: auto;
    }

    .Settings--icon {
        display: inline-block;
        padding: 10px;
    }

    @media only screen and (max-width: 600px) {
        .Settings--icon {
            padding: 5px 0px;
        }
    }

    .Settings--title {
        font-size: 18px;
        color: #595959;
    }

    .Settings--subtitle {
        font-size: 15px;
        display: block;
        color: #848484;
    }

    .Settings--title--text {
        padding: 20px 20px 20px 0px;
    }

    @media only screen and (max-width: 800px) {
        .Settings--title--text {
            padding: 5px 5px 5px 0px;
        }
    }

    .editorTabIcon {
        font-size: 18px;
        width: 100%;
        height: auto;
        margin: 0 auto;
    }

    @media only screen and (max-width: 970px) {
        .editorTabIcon {
            font-size: 14px;
            word-wrap: break-word;
        }
    }

    .editorTabIcon img {
        display: block;
        margin: 8px auto;
    }

    .Settings--container .tab-container {
        background-color: #1e1e1e;
        padding: 0 10%;
    }

    @media only screen and (max-width: 970px) {
        .Settings--container .tab-container {
            padding: 0px;
        }
    }

    .ColorWayPicker .tab {
        color: #4f4f4f;
        font-size: 14px;
        padding: 20px;
    }

    @media only screen and (max-width: 900px) {

        .ColorWayPicker .tab {
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 5px 10px;
            width: 100%;
        }

        .ColorWayPicker .tab--selected {
            border-left: 3px solid rgb(117, 68, 234);
        }

        .ColorWayPicker .tab-container {
            flex-direction: column;
            align-items: flex-start;
        }

    }

    .ColorWayPicker .tab:hover,
    .ColorWayPicker .tab--selected {
        color: #4f4f4f;
        background-color: transparent;
        font-size: auto;
    }


    .ColorWayPicker .tab:after {
        content: '';
        display: block;
        border-bottom: 2px solid #595959;
        width: 100%;
        transform : translateX(-200%);
        position: relative;
        top: 20px; /* 20px = padding for tab */
    }

    @media only screen and (max-width: 900px) {
        .ColorWayPicker .tab:after {
            display: none;
        }
    }

    .ColorWayPicker .tab--selected:after {
        -webkit-transition: 0.5s ease;
        transition: 0.5s ease;
        transform : translateX(0%);
     }

    .ColorWayPicker .tab-container {
        background-color: transparent;
        border: 1px solid #d8d8d8;
        padding: 0;
    }

    .editor--icon {
        width: 25px;
    }

    .editorTabIcon .editor--icon {
        width: 30px;
    }

    .saveTheme--buttons .editor--icon {
        width: 17px;
    }

    .Settings--info--container {
        display: flex;
        align-items: center;
        margin: 20px;
        margin-left: 0px;
    }

    @media only screen and (max-width: 800px) {
        .Settings--info--container {
            flex-direction: column;
            margin: 0;
            margin-bottom: 15px;
            width: 100%;
        }
    }

    #Settings--icon--reverse {
        float: right;
        cursor: pointer;
        transition: all 0.25s;
    }

    .reverse#Settings--icon--reverse {
        -webkit-transform: scaleX(-1);
        -ms-transform: scaleX(-1);
        transform: scaleX(-1);
    }

    @media only screen and (max-width: 600px) {
        #Settings--icon--reverse {
            float: none;
            display: block;
        }

        #Settings--icon--reverse .editor--icon {
            display: block;
            margin: 0 auto;
        }
    }

    .Settings--info {
        border-left: 6px solid #7544ea;
        background-color: #f9f9f9;
        color: #848484;
        font-size: 14px;
        padding: 10px;
    }

    .saveTheme--buttons {
        display: flex;
        margin-left: auto;
        align-items: center;
        font-size: 15px;
    }

    @media only screen and (max-width: 600px) {
        .saveTheme--buttons {
            margin-left: initial;
        }
    }

    .saveTheme--buttons button {
        border: 1px solid #d8d8d8;
        border-radius: 5px;
        color: #848484;
        font-size: 17px;
        line-height: 40px;
        background-color: white;
    }

    .saveTheme--buttons button img {
        vertical-align: middle;
        margin: 10px;
    }

    .themeCode {
        border: thin lightgrey solid;
        background-color: white;
        padding-left: 10;
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        font-weight: 300;
        padding: 28px 67px;
        overflow: auto;
    }

    @media only screen and (max-width: 600px) {
        .themeCode {
            font-size: 14px;
        }
    }

    .borderPicker--container .Select {
        min-width: 75px;
    }

    .FontPicker--option {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    /* PRESET THEMES TAB */

    .presetsContainer {
        display: flex;
        flex-wrap: wrap;
    }

    .PresetTab {
        width: 20vw;
        padding: 8px;
        margin: 15px;
        border: 1px solid #d8d8d8;
        cursor: pointer;
        background-color: #fff;
    }

    .PresetTab.PresetTab--current,
    .PresetTab:hover {
        border: 1px solid #ff2c78;
    }

    .Preset--swatch-container {
        position: relative;
    }

    .Preset--swatch-container:after, .Preset--swatch-container:before {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.3s ease-in-out;
        bottom: -11px; /* padding of swatch + 1px */
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    .Preset--swatch-container:after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #fff;
        border-width: 30px;
        margin-left: -30px;
    }
    .Preset--swatch-container:before {
        border-color: rgba(216, 216, 216, 0);
        border-bottom-color: #d8d8d8;
        border-width: 31px;
        margin-left: -31px;
    }


    .Preset--swatch {
        height: 100%;
        display: inline-block;
        float: left;
    }

    .Preset--swatch-container {
        height: 10vw;
    }

    .Preset--swatch-background_content {
        width: 53%;
    }

    .Preset--swatch-background_page {
        width: 19%;
    }

    .Preset--swatch-accent {
        width: 12%;
    }

    .Preset--swatch-border,
    .Preset--swatch-text {
        width: 16%;
        height: 50%;
    }

    .themeHover {
        visibility: hidden;
        opacity: 0;
        background-color: rgba(255, 255, 255, 0);
        transition: visibility 0s, background-color 0.3s ease-in-out, opacity 0.3s ease-in-out;
        padding: 30px;
        color: #777777;
        background-color: #fff;
        position: absolute;
        left: 0;
        border: 1px solid #d8d8d8;
        margin: 0px 10%;
        margin-top: 10px; /* padding of swatch */
    }

    .themeHover:hover {
        display: none;
    }

    @media only screen and (max-width: 600px) {
        .themeHover:hover {
            display: block;
        }
    }

    .Preset--slice {
        display: inline-flex;
    }

    @media only screen 
        and (min-width: 600px) 
        and (max-width: 1200px) {
        .Preset--slice {
            flex-direction: column;
        }
        .ColorTab {
            padding: 5px;
        }
    }

    .Preset--slice-swatch {
        width: 30%;
        padding-bottom: 30%;
        display: inline-block;
        margin-right: 20px;
    }

    @media only screen and (max-width: 600px) {
        .Preset--slice {
            display: block;
            width: 100% !important;
        }
        .Preset--slice-swatch {
            width: 15%;
            padding-bottom: 15%;
        }
    }

    .Preset--slice-desc {
        font-size: 13px;
        padding-right: 5px;
        display: inline-block;
        vertical-align: top;
        text-transform: capitalize;
    }

    .Preset--slice-desc .color-hex {
        text-transform: uppercase;
    }


    .PresetTab:hover .themeHover {
        visibility: visible;
        opacity: 1;
        background-color: rgba(255, 255, 255, 0.95);
        z-index: 2;
    }

    .PresetTab:hover .Preset--swatch-container:after, .PresetTab:hover .Preset--swatch-container:before {
        visibility: visible;
        opacity: 1;
        z-index: 3;
    }

    .Preset--fontPreview-container {
        display: flex;
        border-top: 1px solid #dbdbdb;
        margin-top: 20px;
    }

    .Preset--fontPreview:first-child {
        padding-right: 50px;
    }

    .Preset--fontPreview:last-child {
        padding-left: 50px;
    }

    @media only screen and (max-width: 600px) {
        .PresetTab {
            width: 35vw;
        }
        .Preset--swatch-container {
            height: 15vw;
        }
    }

    .Settings-containers .presetsContainer.border-presets .PresetTab,
    .Settings-containers .presetsContainer.border-presets .PresetTab,
    .Settings-containers .presetsContainer.button-presets--style .PresetTab,
    .Settings-containers .presetsContainer.button-presets--border-radius .PresetTab {
        flex: 1;
        min-width: 200px;
    }


    .Settings-containers .presetsContainer.button-presets--border-radius .PresetTab,
    .Settings-containers .presetsContainer.inputs-background-colors .PresetTab,
    .Settings-containers .presetsContainer.inputs-custom-colors .PresetTab {
        width: 25%;
    }

    .popout-content-container .rc-slider-tooltip {
        display: none;
    }

    .swatchSlider .rc-slider-handle {
        border-color: #7544ea;
    }

    .swatchSlider .rc-slider-handle:hover {
        border-color: #7544ea;
    }

    .swatchSlider .rc-slider-track {
        background-color: #7544ea;
    }

    .colorscalePickerContainer .colorscaleLabel {
        width: auto;
        padding-right: 0px;
    }

    .editor .dragDropSwatches {
        margin-bottom: 15px;
    }

    .editor .colorscaleLabel.colorscaleDragDescription {
        width: auto;
        padding: 10px 5px;
    }

    input.invalid-input {
        background-size: 15px;
        background-repeat: no-repeat;
        background-position: right;
        background-origin: content-box;
        border: 1px dotted #FA4F56;
    }

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        .edit-theme-button {
            cursor: not-allowed;
        }
        .edit-theme-button:after {
            content: " (not supported in IE)";
        }
    }

    body.waiting * {
        cursor: wait !important;
    }

    /* EDITOR CONTAINERS */

    .Settings-containers .presetsContainer {
        flex-wrap: nowrap;
    }

    .Settings-containers .PresetTab {
        font-size: 13px;
        width: auto;
    }

    .containersRow {
        display: flex;
        flex-wrap: wrap;
    }

    .containersRow .cardPreview {
        border: 1px solid black;
        height: 100%;
        margin: 20px;
    }

    .borderPicker--container,
    .shadowPicker--container {
        width: 100%;
        display: flex;
        align-items: center;
        margin: 2px 0px;
    }

    .swatchInput--container {
        position: relative;
        background: linear-gradient(to right, transparent 75%, #c4e17f, #f7fdca, #fad071, #f0766b, #db9dbe, #c49cdf, #6599e2, #61c2e4);
        cursor: pointer;
    }

    .swatchInput--container .dragDropPicker {
        z-index: 1;
    }

    .borderPicker--container.borderRow > div:first-child {
        width: 45px;
    }

    .borderPicker--container > div {
        margin: 0px 8px;
    }

    .borderPicker--container.borderRow > div,
    .borderPicker--container.outlineRow > div {
        width: 105px;
    }

    .shadowPicker--container > div {
        margin: 0px 10px;
        width: 50px;
    }

    .shadowPicker--container > div:last-of-type {
        width: 130px;
    }

    .containerAttribute .Settings--title--container {
        flex-wrap: wrap;
    }

    .Swatch--input {
        text-align: center;
        border: none;
        text-overflow: ellipsis;
        width: 75%;
    }

    .ThemeDefinition-tabs .copyText {
        cursor: pointer;
        height: 17px;
        width: 17px;
    }

    /* Copy icon + tooltip */

    .copyText svg .cls-1,
    .copyText svg .cls-2 {
        transition: all 0.5s ease;
    }

    .copyText svg .cls-3 {
        opacity: 0;
        transition: all 0.5s ease;
        transform: translate(15px,-12px);
    }

    .copyText.copied svg .cls-3 {
        opacity: 1;
        transform: translate(0px,0px);
    }

    .copyText {
        position: relative;
    }

    .copyText > div > div {
       display: flex;
       align-items: center;
    }

    .copyText.copied:before {
        content: "";
        position: absolute;
        left:50%;
        transform: translateX(-50%);
        border-width: 4px 6px 0 6px;
        border-style: solid;
        border-color: rgba(0,0,0,0.7) transparent transparent transparent;
        z-index: 100;
        top: calc(100% + 1px);
        transform: translateX(-50%) translatey(-100%) rotate(-180deg)
    }

    .copyText.copied:after {
        content: "Text copied to clipboard";
        position: absolute;
        top:100%;
        transform: translateX(-50%) translateY(0%);
        left: 50%;
        background: rgba(0,0,0,0.7);
        border-width: 1px;
        border-style: solid;
        border-color: black;
        text-align: center;
        color: #fff;
        padding: 4px 2px;
        font-size: 12px;
        min-width: 66px;
        border-radius: 5px;
        pointer-events: none;
    }

    .editor .tab-content .copyText {
        display: flex;
        cursor: pointer;
    }

    .editor .tab-content .copyText svg {
        width: 17px;
        margin: 10px;
        margin-left: 0px;
    }

    .editor #saveTheme {
        display: flex;
    }

    .editor #saveTheme:hover {
        color: rgb(255, 44, 120)
    }

    .editor .copyText span {
        line-height: 37px;
        color: rgb(132, 132, 132);
    }

    .editor .copyText span:hover {
        color: rgb(255, 44, 120)
    }

    .editor .copyText svg .cls-1 {
        fill: #7544ea;
    }

    .editor .copyText svg .cls-2,
    .editor .copyText svg .cls-3 {
        fill: #ff2c78;
    }

    .editor .copyText:hover svg .cls-1 {
        fill: #ff2c78;
    }

    .editor .copyText:hover svg .cls-2 {
        fill: #7544ea;
    }

    .editor .copyText.copied:before {
        border-color: black transparent transparent transparent;
    }

    .editor .copyText.copied:after {
        background: white;
        color: black;
        border-color: lightgrey;
    }

    .copyDef-container {
        display: flex;
        justify-content: center;
        border: 1px solid lightgrey;
        border-radius: 5px;
        padding: 0px 15px;
        font: 400 17px Arial;
    }

    .Advanced-tabs .containerAttribute {
        margin: 10px;
    }

    .Advanced-tabs + .tab-content {
        background-color: white;
        padding: 10px;
        width: 100%;
    }

    /* override inaccurate calc of tab width based on nested tabs */
    @media screen and (min-width: 800px) {
        .Advanced-tabs.Colors-tabs .tab {
            width: 50%;
        }
    }

    /* on Firefox, input elements overflow their div containers */
    /* @-moz-document targets FF only */
    @-moz-document url-prefix() {
        .Advanced-tabs + .tab-content input:not(.Swatch--input) {
            max-width: 60px;
        }
    }

    @media only screen and (max-width: 1000px) {
        .Advanced-tabs + .tab-content input:not(.Swatch--input) {
            width: 100px;
            max-width: 100%;
        }

        @-moz-document url-prefix() {
            .Advanced-tabs + .tab-content input:not(.Swatch--input) {
                max-width: 60px;
            }
        }

        .borderPicker--container.borderRow > div,
        .borderPicker--container.outlineRow > div {
            width: 75px;
        }
    }

    @media only screen and (max-width: 1100px) {
        .Settings-containers .presetsContainer {
            flex-wrap: wrap
        }
        .Settings-containers .presetsContainer .PresetTab {
            /* 30px = 15px margin * 2 */
            width: calc(50% - 30px)
        }
    }

    @media only screen and (max-width: 700px) {

        .Settings-containers .PresetTab {
            margin: 5px;
        }

        .Settings-containers .PresetTab {
            font-size: 10px;
        }
    }

    @media only screen and (max-width: 600px) {

        .PresetTab {
            margin: 5px;
        }

        .Advanced-tabs + .tab-content {
            background-color: white;
            padding: 10px;
            width: 100%;
        }

        .Advanced-tabs + .tab-content .containersRow.bordersRow,
        .Advanced-tabs + .tab-content .containersRow.shadowsRow {
            overflow-x: auto;
        }

    }

    .editor .Settings--container .tab-container.Advanced-tabs,
    .editor .Settings--container .tab-container.ThemeDefinition-tabs {
        width: 50%;
        background-color: transparent;
        color: rgb(89, 89, 89);
        padding: 0px;
        color: black;
    }

    .editor .Settings--container .tab-container.Advanced-tabs .tab,
    .editor .Settings--container .tab-container.ThemeDefinition-tabs .tab {
        color: rgb(80, 103, 132);
        font-size: 15px;
        background-color: #f8f8f8;
        padding: 10px;
        border-top: 1px solid #eaeaea;
        border-bottom: 5px solid #eaeaea;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .editor .Settings--container .tab-container.Advanced-tabs .tab:first-child,
    .editor .Settings--container .tab-container.ThemeDefinition-tabs .tab:first-child {
        border-left: 1px solid #eaeaea;
    }

.editor .Settings--container .tab-container.Advanced-tabs .tab:last-child,
    .editor .Settings--container .tab-container.ThemeDefinition-tabs .tab:last-child {
        border-right: 1px solid #eaeaea;
    }

    .editor .Settings--container .tab-container.Advanced-tabs .tab--selected,
    .editor .Settings--container .tab-container.Advanced-tabs .tab:hover,
    .editor .Settings--container .tab-container.ThemeDefinition-tabs .tab--selected,
    .editor .Settings--container .tab-container.ThemeDefinition-tabs .tab:hover {
        border-bottom: 5px solid #7544EA;
        background-color: white;
        color: #7544EA;
    }

    /* hack around bad Safari support */
    _::-webkit-full-page-media, _:future, :root .copyDef-container {
        max-height: 43px;
    }
`;

export default styles;
