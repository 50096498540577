const isDashLegacy = component => !component.props._dashprivate_layout;

export default {
    getType: component =>
        isDashLegacy(component) ?
            component.props.children.type.name :
            component.props._dashprivate_layout.type,

    getProps: component =>
        isDashLegacy(component) ?
            component.props.children.props :
            component.props._dashprivate_layout.props
}