/* eslint-disable react/prop-types */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {omit} from 'ramda';

/**
 * Creates a nice looking section title.
 *
 * **Usage**
 * ```
 * ddk.SectionTitle('Quarterly Earnings')
 * ```
 */
export default class SectionTitle extends Component {
    render() {
        return (
            <div
                className={`section-title ${this.props.className || ''}`}
                {...omit(['className'], this.props)}
            >
                <div className='section-title--text'>
                    {this.props.children}
                </div>
                <div className='section-title--line'/>
            </div>
        )
    }
}

SectionTitle.propTypes = {
    /**
     * The ID of this component, used to identify dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    'id': PropTypes.string,

    /**
     * The title of the SectionTitle
     */
    'children': PropTypes.node,

    /**
     * The component's outermost className
     */
    'className': PropTypes.string,

    /**
     * The component's outermost style
     */
    'style': PropTypes.object
}
