import { css } from '../utils.js';
import tinycolor from 'tinycolor2'

const getContrastingColor = (baseColor, theme) => {
    const { background_content, background_page, text, accent } = theme
    return tinycolor.mostReadable(
        baseColor,
        [background_content, background_page, text, accent],
        /* returns black or white if none of the supplied
           theme colors are readable enough */
        {includeFallbackColors:true}
    ).toHexString();
}

const themedEditButton = css`
    .ddk-container.ddk-container button:not(.btn):not([type="button"]).edit-theme-button {
        /* get the best contrast with the page background */
        background-color: ${props => getContrastingColor(props.theme.background_page, props.theme)};
        /* now get the best (most readable) text contrast from the above background */
        color: ${props => getContrastingColor(getContrastingColor(props.theme.background_page, props.theme), props.theme)};
        position: fixed;
        font-family: sans-serif;
        bottom: 10px;
        left: 10px;
        z-index: 1000;
        user-select: none;
        outline-style:none;
        box-shadow:none;
        border-color:transparent;
        border-radius: 4px;
    }

    .ddk-container.ddk-container button.edit-theme-button:hover {
        background-color: inherit;
        color: inherit;
    }
`
export default themedEditButton;
