import React, { Component } from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import PropTypes from 'prop-types';
import { filter, zip, isNil, find, propOr, findIndex, partial, propEq, mergeAll} from 'ramda';

import Icon from './components/Icon.react.js';

export const NotificationStoreContext = React.createContext();

/**
 * A component for displaying notifications to the user. 
 *
 * **Example Usage**
 * ```
 *   ddk.NotificationStore(
 *       id="store", 
 *       timeout=5*1000, 
 *       displayed=True, 
 *       type_display=[True, True, True] # Array representing 'info', 'warn', 'danger' 
 *       width='250px', # default is 20% of screen
 *       children=[ # should only have alerts as children
 *           ddk.Alert(...)]
 *       )
 * ```
 */
class NotificationInner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initialTime: new Date(),
            folded: this.props.folded
        };
    }

    componentDidMount() {
        const {
            id,
            deleteFunction,
            defaultTimeout
        } = this.props
        const timeout = this.props.timeout ?? defaultTimeout;
        if (timeout > -1) {
            setTimeout(partial(deleteFunction, [id]), timeout);
        }
    }

    render() {
        const {
            id,
            children,
            displayed,
            user_dismiss,
            title,
            deleteFunction,
            style
        } = this.props;
        const folded = this.state.folded

        return (
            displayed ?
                <div
                    style={style}
                    className={`notification ${propOr('', 'className', this.props)} notification--${propOr('info', 'type', this.props)}`}
                    id={id}
                    key={id}
                    folded={Boolean(folded).toString()}
                >
                    {title && (
                        <div className='notification--title'>
                            <div className='notification--title--inner'>
                                {title}
                            </div>
                        </div>
                    )}
                    {user_dismiss && (
                        <div className='notification--delete' onClick={partial(deleteFunction, [id])}>
                            <Icon icon_name='times' icon_category='solid' />
                        </div>
                    )}
                    <div className='notification--collapse'
                        onClick={() => this.setState((currentState) => ({
                            folded: !currentState.folded,
                        }))}>
                        {folded ?
                            <Icon icon_name='angle-down' icon_category='solid' /> :
                            <Icon icon_name='angle-up' icon_category='solid' />
                        }
                    </div>
                    <div className='notification--content'>
                        {children}
                    </div>
                </div>
                : null
        )
    }
}
const isStored = (notification, prevNotifications) => {
    const val = Boolean(find(propEq('id', notification.id))(prevNotifications))
    return val;
}

class MemStore {
    constructor() {
        this._data = {};
        this._modified = -1;
    }

    getItem(key) {
        return this._data[key];
    }

    setItem(key, value) {
        this._data[key] = value;
        this.setModified(key);
    }

    removeItem(key, id) {
        const index = findIndex(propEq('id', id))(this._data[key]);
        if (index !== -1) {
            this._data[key].splice(index, 1);
            this.setModified(key);
        } else {
            throw new Error(`Error in NotificationStore ${key}. Attempted to remove Notification with id: ${id}`);
        }
    }

    // noinspection JSUnusedLocalSymbols
    setModified(_) {
        this._modified = Date.now();
    }

    // noinspection JSUnusedLocalSymbols
    getModified(_) {
        return this._modified;
    }
}

class NotificationStore extends Component {
    constructor(props) {
        super(props);
        this._backstore = new MemStore;
        this.deleteNotification = this.deleteNotification.bind(this);
        this.addNotification = this.addNotification.bind(this);
        this.state = {
            mobileDisplay: false,
        }
        this.storeRef = React.createRef();
        this.mobileButtonRef = React.createRef();
    }

    addNotification(notificationProps) {
        const prevNotifications = this._backstore.getItem(this.props.id);
        if (!isNil(prevNotifications) && !isNil(notificationProps) && !isNil(notificationProps.id) && !isStored(notificationProps, prevNotifications)) {
            prevNotifications.push(notificationProps);
            this._backstore.setItem(this.props.id, prevNotifications);
        }
    }

    deleteNotification(id) {
        this._backstore.removeItem(this.props.id, id);
        this.forceUpdate();
    }

    componentDidMount() {
        const { id } = this.props;
        const prevNotifications = this._backstore.getItem(id);
        if (isNil(prevNotifications)) {
            // Initial data mount
            this._backstore.setItem(id, []);
        }
    }

    render() {
        const {
            id,
            displayed,
            border_radius,
            type_display,
            width,
            timeout
        } = this.props;
        const display_map = new Map(zip(['danger', 'warn', 'info'], type_display));
        const memChildrenUnfiltered = this._backstore.getItem(id);
        const memChildren = 
        !isNil(memChildrenUnfiltered) ? filter((x) => (display_map.get(x.type)), memChildrenUnfiltered) : memChildrenUnfiltered;
        const mobileDisplay = this.state.mobileDisplay; 

        /* merge conditional styles from props */
        const style = mergeAll([
            {
                borderRadius: border_radius,
                width: width
            },
            propOr({}, 'style', this.props)
        ]);

        if (this.storeRef?.current) {
            this.storeRef.current.classList.remove('notification-store--contains-danger');
        }
        if (this.mobileButtonRef?.current) {
            this.mobileButtonRef.current.classList.remove('notification-store--contains-danger');
        }

        return (
            <React.Fragment>
                <NotificationStoreContext.Provider value={
                    (notificationProps) => this.addNotification(notificationProps)
                }>
                    {this.props.children}
                </NotificationStoreContext.Provider>

                {(displayed && memChildren && Boolean(memChildren.length)) && (
                    <React.Fragment>
                        <div
                            className={'notification-store-mobile-button'}
                            onClick={() => this.setState((currentState) => ({
                                mobileDisplay: !currentState.mobileDisplay,
                            }))}
                            ref={this.mobileButtonRef}
                        >
                            <Icon icon_name='bell' icon_category='solid' />
                            <div className='notification-store-mobile-button--alert-count'>
                                {memChildren && memChildren.length}
                            </div>
                        </div>
                        <div
                            style={style}
                            className={`notification-store ${propOr('', 'className', this.props)}`}
                            id={id}
                            ref={this.storeRef}
                            folded={(!mobileDisplay).toString()}
                        >
                            <React.Fragment>
                                <CSSTransitionGroup
                                    component={React.Fragment}
                                    transitionName="notification"
                                    transitionEnterTimeout={700}
                                    transitionLeaveTimeout={250}
                                >
                                    {
                                        memChildren.reverse().map((props) => {
                                            if (props.type === 'danger') {
                                                if (this.storeRef?.current) {
                                                    this.storeRef.current.classList.add('notification-store--contains-danger');
                                                }
                                                if (this.mobileButtonRef?.current) {
                                                    this.mobileButtonRef.current.classList.add('notification-store--contains-danger');
                                                }
                                            }
                                            return (
                                                <NotificationInner
                                                    deleteFunction={this.deleteNotification}
                                                    defaultTimeout={timeout}
                                                    {...props}
                                                    key={props.id}
                                                />
                                            )
                                        })
                                    }
                                </CSSTransitionGroup>
                            </React.Fragment>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

NotificationStore.defaultProps = {
    timeout: 5 * 1000,
    displayed: true,
    children: [],
    type_display: [true, true, true],
    width: '350px'
}

NotificationStore.propTypes = {
    /**
     * The ID of this component, used to identify Dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    id: PropTypes.string,

    /**
     * The list of components that are children of the NotificationStore.
     */
    children: PropTypes.node,

    /**
     * The time in milliseconds for which the NotificationStore will
     * remain onscreen. Default is 5*1000. -1 indicates no timeout.  
     */
    timeout: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),

    /**
     * Whether or not Notifications in NotificationStore are displayed.
     */
    displayed: PropTypes.bool,

    /**
     * Optional user-defined CSS class for the NotificationStore
     */
    className: PropTypes.string,

    /**
     * Optional additional CSS styles.
     * - If `width`, `padding`, or `margin` are supplied within `style`,
     * then this will override the component-level `width`, `padding`, or `margin`.
     */
    style: PropTypes.object,

    /**
     * Default width of each Notification in pixels.
     * Will be overridden by the `width` property of individual Notifications.
     */
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    /**
     * The border-radius in 'px' of the Notification container.
     */
    border_radius: PropTypes.string,

    /**
     * Array of booleans for filtering which types of array representing 'info', 'warn', 'danger' 
     */
    type_display: PropTypes.arrayOf(PropTypes.bool),

    /**
     * Dash-assigned callback that gets fired when the value changes.
     */
    setProps: PropTypes.func
}



export default NotificationStore;
