 /* eslint-disable react/prop-types */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {merge, propOr} from 'ramda';


class SidebarContent extends Component {
    constructor(props) {
        super(props);
        //TODO: receiveProps from vanilla JS setAttribute() manipulation
        this.state = {
            folded: this.props.folded
        }
    }

    render() {
        return (
            <div
                className="sidebar--content"
                folded={this.state.folded}
            >
                {this.props.children}
            </div>
        )
    }
}

/**
 * A `Sidebar` is an app-level component used for displaying
 * a logo (`ddk.Logo`), a descriptive title of the app (`ddk.Title`),
 * and/or a set of links (`ddk.Menu`).
 *
 * This can be used with our without a `ddk.Header`.
 *
 * **Example Usage**
 * ```
 * ddk.Sidebar(
 *     foldable=False,
 *     children=[
 *         ddk.Title('Economic Indicators'),
 *         ddk.Logo(src='/assets/my-logo.png'),
 *         ddk.Menu([
 *             ddk.CollapsibleMenu(
 *                 title='Monetary Data',
 *                 children=[
 *                     dcc.Link(
 *                         'Monetary Base',
 *                         href='/monetary-base'
 *                     ),
 *                     dcc.Link(
 *                         'Money Velocity',
 *                         href='/money-velocity'
 *                     ),
 *                     dcc.Link(
 *                         'Reserves',
 *                         href='/reserves'
 *                     ),
 *                     dcc.Link(
 *                         'Borrowings',
 *                         href='/borrowings'
 *                     )
 *                 ]
 *             ),
 *             dcc.Link('Conditions', href='/conditions'),
 *             dcc.Link('Investment', href='/investments'),
 *             dcc.Link('Other', href='/other'),
 *         ])
 *     ]
 * )
 * ```
 */
export default class Sidebar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            folded: undefined
        }
    }

    render() {
        const {
            children,
            id,
            foldable,
            ...props
        } = this.props;

        const style = merge({
            'maxWidth': '250px'
        }, propOr({}, 'style', this.props));

        return (
            <div
                data-foldable={foldable}
                className={`layout-sidebar ${propOr('', 'className', this.props)}`}
                style={style}
                id={id}
            >
                <SidebarContent>
                    {children}
                </SidebarContent>
            </div>
        )
    }
}

Sidebar.propTypes = {
    /**
     * The contents of the Sidebar.
     * This is frequently a list containing
     * a `ddk.Logo`, a `ddk.Title`, and a `ddk.Menu`:
     * ```
     * [
     *     ddk.Logo(src='/assets/logo.png'),
     *     ddk.Title('Header Title'),
     *     ddk.Menu([
     *         dcc.Link('Historical', href='/historical'),
     *         dcc.Link('Forecast', href='/forecast'),
     * ]
     * ```
     * but it can also contain arbitrary components
     * like controls or buttons.
     */
    children: PropTypes.node,

    /**
     * Optional user-defined CSS class for the Sidebar component.
     */
    className: PropTypes.string,

    /**
     * The ID of this component, used to identify Dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    id: PropTypes.string,

    /**
     * If True, then the Sidebar can be collapsed.
     * With `foldable` sidebars, we recommend using icons in your menu items
     * so that the links are still visible when collapsed.
     */
    foldable: PropTypes.bool,

    /**
     * The style object of the outermost div of the Sidebar.
     * Use this to override the Sidebar's width
     * (`{'maxWidth': '250px'}`) or the background color
     * (`{'backgroundColor': 'white'}`)
     */
    style: PropTypes.object,
}
