const INDENT = 0;
const DEDENT = 1;
const LINEBREAK = 2;

function loadFlat() {
    if (!Array.prototype.flat)  {
        Array.prototype.flat = function (depth = 1) {
            depth = isNaN(depth) ? 0 : Math.floor(depth); 
            if (depth < 1) return this.slice(); 
            return [].concat (
                ... (depth <2)
                  ? this
                  : this.map (v => Array.isArray (v) ? v.flat (depth - 1) : v) 
            )
        };
    }
}

class Visitor {
    serializeArray(value) {
        let code = [
            ...this.array[0],
            ...value.map(v => this.serialize(v)).map(v => [
                ...v,
                ...this.separator
            ]),
            ...this.array[1]].flat(Number.MAX_SAFE_INTEGER);

        if (code[-this.array[1].length - 1] === this.separator[-1] &&
            code[-this.array[1].length - 2] === this.separator[-2]
        ) {
            code.splice(-this.array[1].length - 2, 1);
        }

        return code;
    }

    serializeObject(value) {
        let code = [
            ...this.object[0],
            ...Object.entries(value).map(([k, v]) => [
                ...this.key(k),
                ...this.assignment,
                ...this.serialize(v)
            ]).map(v => [
                ...v,
                ...this.separator
            ]),
            ...this.object[1]].flat(Number.MAX_SAFE_INTEGER);

        if (code[-this.object[1].length - 1] === this.separator[-1] &&
            code[-this.object[1].length - 2] === this.separator[-2]
        ) {
            code.splice(-this.object[1].length - 2, 1);
        }

        return code;
    }

    visit(value) {
        return this.serialize(value);
    }
}

export class JsVisitor extends Visitor {
    constructor() {
        super();
        loadFlat();
        this.object = [['{', INDENT, LINEBREAK], [DEDENT, '}']];
        this.array = [['[', INDENT, LINEBREAK], [DEDENT, ']']];
        this.assignment = [':'];
        this.separator = [',', LINEBREAK];
    }

    key(key) {
        return [/^[_a-z][a-z0-9_]+$/i.test(key) ? key : `"${key}"`];
    }

    serialize(value) {
        const type = typeof value;
        if (type === 'boolean' || type === 'number' || type === 'string') {
            return [JSON.stringify(value)];
        } else if (type === 'undefined' || value === null) {
            return ['null'];
        } else if (Array.isArray(value)) {
            return this.serializeArray(value);
        } else {
            return this.serializeObject(value);
        }
    }
}

export class PyVisitor extends Visitor {
    constructor() {
        super();
        loadFlat();
        this.object = [['{', INDENT, LINEBREAK], [DEDENT, '}']];
        this.array = [['[', INDENT, LINEBREAK], [DEDENT, ']']];
        this.assignment = [':'];
        this.separator = [',', LINEBREAK];
    }

    key(key) {
        return [`"${key}"`];
    }

    serialize(value) {
        const type = typeof value;
        if (type === 'number' || type === 'string') {
            return [JSON.stringify(value)];
        } else if (type === 'boolean') {
            return value ? ['True'] : ['False'];
        } else if (type === 'undefined' || value === null) {
            return ['None'];
        } else if (Array.isArray(value)) {
            return this.serializeArray(value);
        } else {
            return this.serializeObject(value);
        }
    }
}

export class RVisitor extends Visitor {
    constructor() {
        super();
        loadFlat();
        this.object = [['list(', INDENT, LINEBREAK], [DEDENT, ')']];
        this.array = [['list(', INDENT, LINEBREAK], [DEDENT, ')']];
        this.assignment = ['='];
        this.separator = [',', LINEBREAK];
    }

    key(key) {
        return [`"${key}"`];
    }

    serialize(value) {
        const type = typeof value;
        if (type === 'number' || type === 'string') {
            return [JSON.stringify(value).replace(/'/g, '"')];
        } else if (type === 'boolean') {
            return value ? ['TRUE'] : ['FALSE'];
        } else if (type === 'undefined' || value === null) {
            return ['NULL'];
        } else if (Array.isArray(value)) {
            return this.serializeArray(value);
        } else {
            return this.serializeObject(value);
        }
    }
}

export const stringify = code => {
    code = code.flat(Number.MAX_SAFE_INTEGER);

    let indent = 0;
    let result = '';

    code.forEach((e, i) => {
        let prev_i = i - 1;
        let needsIndent = false;

        while (prev_i >= 0) {
            const prev_e = code[prev_i];
            if (prev_e === LINEBREAK) {
                needsIndent = true;
                break;
            } else if (typeof prev_e === 'string') {
                break;
            }

            prev_i--;
        }

        if (e === INDENT) {
            indent++;
        } else if (e === DEDENT) {
            indent--;
        } else if (e === LINEBREAK) {
            result += '\n';
        } else {
            // n + 1 elements joined by n delimiters (separators) = n spaces
            result += (needsIndent ? new Array(indent * 4 + 1).join(' ') : '') + e;
        }
    });

    return result;
};
