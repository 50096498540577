import { css, objectToStyles, getValueFromCSSText } from '../utils.js';
import chroma from 'chroma-js';
import * as R          from 'ramda';

const themedStyles = css`
    .ddk-container.ddk-container button:not(.btn):not(.edit-theme-button):not([type="button"]):active:enabled,
    .ddk-container.ddk-container input[type="submit"]:active:enabled,
    .ddk-container.ddk-container input[type="reset"]:active:enabled,
    .ddk-container.ddk-container input[type="button"]:active:enabled {
        color: ${props => 
            props.theme.button_background_color === props.theme.background_content
              ? props.theme.button_text
              : ''
        };
        border-color: ${props => 
            (props.theme.button_background_color === props.theme.background_content
            || props.theme.button_background_color === props.theme.button_border.color)
              ? props.theme.button_border.color
              : ''
        };
        background-color: ${props => 
            props.theme.button_background_color !== props.theme.background_content
              ? props.theme.button_background_color
              : ''
        };
        border-bottom: ${props =>
            getValueFromCSSText(
                'borderBottomWidth',
                'borderWidth',
                props.theme.button_border.width
            ) === '4px' ? 'none' : ''
        }
    }

    .ddk-container.ddk-container button[disabled] {
	    cursor: not-allowed;
	}

	.ddk-container.ddk-container input:not([type]),
	.ddk-container.ddk-container input[type="email"],
	.ddk-container.ddk-container input[type="number"],
	.ddk-container.ddk-container input[type="search"],
	.ddk-container.ddk-container input[type="text"],
	.ddk-container.ddk-container input[type="tel"],
	.ddk-container.ddk-container input[type="url"],
	.ddk-container.ddk-container input[type="password"],
	.ddk-container.ddk-container textarea,
	.ddk-container.ddk-container select {
        border-width: ${props => props.theme.control_border.width};
        border-color: ${props => props.theme.control_border.color};
        border-radius: ${props => props.theme.control_border.radius};
        background-color: ${props => props.theme.control_background_color};
        color: ${props => props.theme.control_text};
	}

	.ddk-container.ddk-container textarea {
        border-width: ${
            props => (props.theme.control_background_color === props.theme.background_content &&
            parseInt(
                getValueFromCSSText(
                    'borderTopWidth',
                    'borderWidth',
                    props.theme.control_border.width
                ),
                10
            ) < 1)
              ? '1px'
              : props.theme.control_border.width
        }
    }

    .ddk-container.ddk-container textarea:disabled {
        color: ${props => chroma(props.theme.control_text).alpha(0.8).css()};
        background-color: ${props => chroma(props.theme.control_background_color).alpha(0.5).css()};
    }

	.ddk-container.ddk-container .layout-header textarea {
        min-height: 0px;
    }

    .ddk-container.ddk-container .layout-header button:not(.btn):not(.edit-theme-button):not([type="button"]) {
        color: ${props =>
            (props.theme.header_text !== props.theme.text
            && props.theme.button_text === props.theme.accent)
              ? 'inherit'
              : props.theme.button_text
        };
        background-color: ${props =>
            (props.theme.header_background_color !== props.theme.background_content
            && props.theme.button_background_color === props.theme.background_content)
              ? 'inherit'
              : props.theme.button_background_color
        };
    }

    .ddk-container.ddk-container .layout-header[style*="color:"] button:not(.btn):not(.edit-theme-button):not([type="button"]) {
        color: ${props =>
            props.theme.button_text === props.theme.accent
              ? 'inherit'
              : props.theme.button_text
        };
    }

    .ddk-container.ddk-container .layout-header[style*="background-color:"] button:not(.btn):not(.edit-theme-button):not([type="button"]) {
        background-color: ${props =>
            props.theme.button_background_color === props.theme.background_content
              ? 'inherit'
              : props.theme.button_background_color
        };
    }

	.ddk-container.ddk-container .layout-header input:not([type]),
	.ddk-container.ddk-container .layout-header input[type="email"],
	.ddk-container.ddk-container .layout-header input[type="number"],
	.ddk-container.ddk-container .layout-header input[type="search"],
	.ddk-container.ddk-container .layout-header input[type="text"],
	.ddk-container.ddk-container .layout-header input[type="tel"],
	.ddk-container.ddk-container .layout-header input[type="url"],
	.ddk-container.ddk-container .layout-header input[type="password"],
	.ddk-container.ddk-container .layout-header textarea,
	.ddk-container.ddk-container .layout-header select {
        color: ${props =>
            (props.theme.header_text !== props.theme.text
            && props.theme.control_text === props.theme.text)
              ? 'inherit'
              : props.theme.control_text
        };
        background-color: ${props =>
            (props.theme.header_background_color !== props.theme.background_content
            && props.theme.control_background_color === props.theme.background_content)
              ? 'inherit'
              : props.theme.control_background_color
        };
    }

	.ddk-container.ddk-container .layout-header > input:not([type])[disabled],
	.ddk-container.ddk-container .layout-header > input[type="email"][disabled],
	.ddk-container.ddk-container .layout-header > input[type="number"][disabled],
	.ddk-container.ddk-container .layout-header > input[type="search"][disabled],
	.ddk-container.ddk-container .layout-header > input[type="text"][disabled],
	.ddk-container.ddk-container .layout-header > input[type="tel"][disabled],
	.ddk-container.ddk-container .layout-header > input[type="url"][disabled],
	.ddk-container.ddk-container .layout-header > input[type="password"][disabled],
	.ddk-container.ddk-container .layout-header > textarea[disabled],
	.ddk-container.ddk-container .layout-header > select[disabled] {
        opacity: 0.5;
    }

	.ddk-container.ddk-container .layout-header[style*="background-color:"] input:not([type]),
	.ddk-container.ddk-container .layout-header[style*="background-color:"] input[type="email"],
	.ddk-container.ddk-container .layout-header[style*="background-color:"] input[type="number"],
	.ddk-container.ddk-container .layout-header[style*="background-color:"] input[type="search"],
	.ddk-container.ddk-container .layout-header[style*="background-color:"] input[type="text"],
	.ddk-container.ddk-container .layout-header[style*="background-color:"] input[type="tel"],
	.ddk-container.ddk-container .layout-header[style*="background-color:"] input[type="url"],
	.ddk-container.ddk-container .layout-header[style*="background-color:"] input[type="password"],
	.ddk-container.ddk-container .layout-header[style*="background-color:"] textarea,
	.ddk-container.ddk-container .layout-header[style*="background-color:"] select {
        background-color: ${props => 
            props.theme.control_background_color === props.theme.background_content
              ? 'inherit'
              : props.theme.control_background_color
        };
    }

	.ddk-container.ddk-container .layout-header[style*="color:"] input:not([type]),
	.ddk-container.ddk-container .layout-header[style*="color:"] input[type="email"],
	.ddk-container.ddk-container .layout-header[style*="color:"] input[type="number"],
	.ddk-container.ddk-container .layout-header[style*="color:"] input[type="search"],
	.ddk-container.ddk-container .layout-header[style*="color:"] input[type="text"],
	.ddk-container.ddk-container .layout-header[style*="color:"] input[type="tel"],
	.ddk-container.ddk-container .layout-header[style*="color:"] input[type="url"],
	.ddk-container.ddk-container .layout-header[style*="color:"] input[type="password"],
	.ddk-container.ddk-container .layout-header[style*="color:"] textarea,
	.ddk-container.ddk-container .layout-header[style*="color:"] select {
        color: ${props => 
            props.theme.control_text === props.theme.text
              ? 'inherit'
              : props.theme.control_text
        };
    }

	.ddk-container.ddk-container input:not([type]):focus,
	.ddk-container.ddk-container input[type="email"]:focus,
	.ddk-container.ddk-container input[type="number"]:focus,
	.ddk-container.ddk-container input[type="search"]:focus,
	.ddk-container.ddk-container input[type="text"]:focus,
	.ddk-container.ddk-container input[type="tel"]:focus,
	.ddk-container.ddk-container input[type="url"]:focus,
	.ddk-container.ddk-container input[type="password"]:focus {
		outline: 'transparent';
	}

	.ddk-container.ddk-container input:disabled {
	    color: ${props => chroma(props.theme.control_text).alpha(0.8).css()};
        background-color: ${props => chroma(props.theme.control_background_color).alpha(0.5).css()};
	}

    .ddk-container.ddk-container .layout-header:not([style*="color:"]) input::disabled {
        color: ${props => 
            (props.theme.header_text !== props.theme.text
            && props.theme.control_text === props.theme.text)
              ? 'inherit'
              : chroma(props.theme.control_text).alpha(0.8).css()
        } !important;
    }

	.ddk-container.ddk-container label {
        margin-left: 5px;
        display: flex;
        align-items: center;
	}
`
export default themedStyles;
