import { css } from '../utils.js';
import chroma from 'chroma-js';

const themedStyles = css`
    .PresetDateRangePicker_button {
      background: none;
      border: 2px solid ${props => props.theme.control_border.color};
      color: ${props => props.theme.control_text};
    }
    .PresetDateRangePicker_button__selected {
      color: ${props => props.theme.control_text};
      background: ${props => props.theme.background};
    }
    .SingleDatePickerInput {
      background-color: ${props => props.theme.control_background_color};
    }
    .SingleDatePickerInput__withBorder {
      border-color: ${props => props.theme.control_border.color};
    }
    .SingleDatePickerInput__disabled {
      background: none;
    }
    .SingleDatePickerInput_clearDate__default:focus {
      background: ${props => props.theme.accent};
    }
    .SingleDatePickerInput_clearDate__default:hover {
      background: ${props => props.theme.accent};
    }
    .SingleDatePickerInput_clearDate_svg {
      fill: ${props => props.theme.control_border.color};
    }
    .SingleDatePickerInput_calendarIcon_svg {
      fill: ${props => props.theme.control_border.color};
    }
    .SingleDatePicker_picker {
      background-color: ${props => props.theme.control_background_color};
    }
    .SingleDatePicker_picker__portal {
      background-color: rgba(0, 0, 0, 0.3);
    }
    .SingleDatePicker_picker__fullScreenPortal {
      background-color: ${props => props.theme.control_background_color};
    }
    .SingleDatePicker_closeButton {
      background: none;
    }
    .SingleDatePicker_closeButton:hover {
      color: ${props => chroma(props.theme.control_background_color).darken().css()};
    }
    .SingleDatePicker_closeButton:focus {
      color: ${props => chroma(props.theme.control_background_color).darken().css()};
    }
    .SingleDatePicker_closeButton_svg {
      background-color: ${props => props.theme.control_background_color};
    }
    .DayPickerKeyboardShortcuts_show__bottomRight::before {
      border-right: 33px solid ${props => props.theme.control_border.color};
    }
    .DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
      border-right: 33px solid ${props => props.theme.control_border.color};
    }
    .DayPickerKeyboardShortcuts_show__topRight::before {
      border-bottom: 26px solid transparent;
      border-right: 33px solid ${props => props.theme.control_border.color};
    }
    .DayPickerKeyboardShortcuts_show__topRight:hover::before {
      border-right: 33px solid ${props => props.theme.control_border.color};
    }
    .DayPickerKeyboardShortcuts_show__topLeft::before {
      border-left: 33px solid ${props => props.theme.control_border.color};
    }
    .DayPickerKeyboardShortcuts_show__topLeft:hover::before {
      border-left: 33px solid ${props => props.theme.control_border.color};
    }
    .DayPickerKeyboardShortcuts_showSpan {
      color: ${props => props.theme.background};
    }
    .DayPickerKeyboardShortcuts_panel {
      background: ${props => props.theme.background};;
      border: 1px solid ${props => props.theme.control_border.color};
    }
    .DayPickerKeyboardShortcuts_closeSvg {
      fill: ${props => props.theme.background};
    }
    .DayPickerKeyboardShortcuts_closeSvg:hover {
      fill: ${props => props.theme.accent}
    }
    .DayPickerKeyboardShortcuts_closeSvg:focus {
      fill: ${props => props.theme.accent}
    }
    .CalendarDay__default {
      border: 1px solid ${props => props.theme.control_border.color};
      color: ${props => props.theme.control_text};
      background: ${props => props.theme.background}
    }
    .CalendarDay__default:hover {
      background: ${props => props.theme.accent}
      border: 1px solid ${props => props.theme.control_border.color};
      color: inherit;
    }
    .CalendarDay__hovered_offset {
      background: ${props => chroma(props.theme.accent).alpha(0.8).css()};
      border: 1px double ${props => props.theme.control_border.color};
      color: inherit;
    }
    .CalendarDay__outside {
      border: 0px;
      background: ${props => props.theme.control_background_color};
      color: ${props => props.theme.control_text}
    }
    .CalendarDay__blocked_minimum_nights {
      background: ${props => props.theme.control_background_color};
      border: 1px solid ${props => props.theme.control_border.color};
      color: ${props => props.theme.control_text}
    }
    .CalendarDay__blocked_minimum_nights:hover {
      background: ${props => props.theme.control_background_color};
      color: ${props => props.theme.control_text};
    }
    .CalendarDay__blocked_minimum_nights:active {
      background: ${props => props.theme.control_background_color};
      color: ${props => props.theme.control_text};
    }
    .CalendarDay__highlighted_calendar {
      background: ${props => chroma(props.theme.accent).alpha(0.8).css()};
      color: ${props => props.theme.control_text}
    }
    .CalendarDay__highlighted_calendar:hover {
      background: ${props => chroma(props.theme.accent).brighten().css()};
      color: ${props => props.theme.control_text};
    }
    .CalendarDay__highlighted_calendar:active {
      background: ${props => chroma(props.theme.accent).brighten().css()};
      color: ${props => props.theme.control_text};
    }
    .CalendarDay__selected_span {
      background: ${props => chroma(props.theme.accent).brighten().css()};
      color: ${props => props.theme.control_text};
      border: 1px double ${props => props.theme.control_border.color};
    }
    .CalendarDay__selected_span:hover,
    .CalendarDay__selected_span:active {
      background: ${props => chroma(props.theme.accent).brighten().css()};
      border: 1px double {props => chroma(props.theme.accent).brighten().css()};
      color: ${props => props.theme.control_text};
    }
    .CalendarDay__selected_span:active {
      background: ${props => chroma(props.theme.accent).darken().css()};
      border: 1px double {props => chroma(props.theme.accent).darken().css()};
      color: ${props => props.theme.control_text};
    }
    .CalendarDay__selected,
    .CalendarDay__selected:hover,
    .CalendarDay__selected:active {
      background: ${props => chroma(props.theme.accent).darken().css()};
      border: 1px double {props => chroma(props.theme.accent).darken().css()};
      color: ${props => props.theme.control_text};
    }
    .CalendarDay__hovered_span,
    .CalendarDay__hovered_span:hover {
      background: ${props => chroma(props.theme.accent).brighten().css()};
      border: ${props => chroma(props.theme.accent).brighten().css()};
      color: ${props => chroma(props.theme.accent).darken().css()};
    }
    .CalendarDay__hovered_span:active {
      background: ${props => chroma(props.theme.accent).brighten().css()};
      border: ${props => chroma(props.theme.accent).brighten().css()};
      color: ${props => chroma(props.theme.accent).darken().css()};
    }
    .CalendarDay__blocked_calendar {
      background-color: ${props => chroma(props.theme.control_background_color).alpha(0.8).css()}
      border: 1px solid ${props => props.theme.control_border.color};
      color: ${props => props.theme.control_text};
    }
    .CalendarDay__blocked_calendar:hover,
    .CalendarDay__blocked_calendar:active,
    .CalendarDay__blocked_out_of_range,
    .CalendarDay__blocked_out_of_range:hover,
    .CalendarDay__blocked_out_of_range:active,
    .CalendarDay__hovered_start_first_possible_end,
    .CalendarDay__hovered_start_blocked_min_nights {
      background-color: ${props => chroma(props.theme.control_background_color).alpha(0.8).css()}
      border: 1px solid ${props => props.theme.control_border.color};
      color: ${props => props.theme.control_text};
    }
    .CalendarMonth {
      background: ${props => props.theme.background};
    }
    .CalendarMonth_caption {
      color: ${props => props.theme.control_text};
    }
    .CalendarMonthGrid {
      background: ${props => props.theme.control_background_color};
    }
    .DayPickerNavigation_button__default {
      border: 1px solid ${props => props.theme.control_border.color};
      background-color: ${props => props.theme.background}
      color: ${props => props.theme.control_text}
    }
    .DayPickerNavigation_button__default:focus {
      border: 1px solid ${props => props.theme.control_border.color};
    }
    .DayPickerNavigation_button__default:hover {
      border: 1px solid ${props => props.theme.control_border.color};
    }
    .DayPickerNavigation_button__default:active {
      background: ${props => props.theme.control_background_color}
    }
    .DayPickerNavigation_button__disabled {
      cursor: default;
      border: 1px solid ${props => props.theme.control_border.color}
    }
    .DayPickerNavigation_button__disabled:focus {
      border: 1px solid ${props => props.theme.control_border.color}
    }
    .DayPickerNavigation_button__disabled:hover {
      border: 1px solid ${props => props.theme.control_border.color}
    }
    .DayPickerNavigation_button__disabled:active {
      background: none
    }
    .DayPickerNavigation_button__verticalDefault {
      background: ${props => props.theme.control_background_color};
      box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    }
    .DayPickerNavigation_svg__horizontal {
      fill: ${props => chroma(props.theme.control_text).alpha(0.8).css()};
    }
    .DayPickerNavigation_svg__vertical {
      fill: ${props => chroma(props.theme.control_text).alpha(0.8).css()};
    }
    .DayPickerNavigation_svg__disabled {
      background-color: ${props => chroma(props.theme.control_background_color).alpha(0.8).css()};
    }
    .DayPicker {
      background-color: ${props => chroma(props.theme.control_background_color).alpha(0.8).css()};
    }
    .DayPicker__horizontal {
      background: ${props => props.theme.control_background_color};
    }
    .DayPicker__withBorder {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05),0 0 0 1px rgba(0, 0, 0, 0.07);
    }
    .DayPicker_weekHeader {
      color: ${props => props.theme.control_text};
    }
    .DateInput {
      background: ${props => props.theme.control_background_color};
    }
    .DateInput__disabled {
      color: ${props => chroma(props.theme.control_text).alpha(0.8).css()};
      background: none;
    }
    .DateInput_input {
      color: ${props => props.theme.control_text};
      background-color: ${props => props.theme.control_background_color};
    }
    .DateInput_input__focused {
      background: ${props => props.theme.control_background_color};
    }
    .DateInput_input__disabled {
      background: ${props => props.theme.control_background_color};
    }
    .DateInput_fangShape {
      fill: ${props => props.theme.control_background_color};
    }
    .DateInput_fangStroke {
      stroke: ${props => props.theme.control_background_color};
      fill: transparent;
    }
    .DateRangePickerInput {
      background-color: ${props => props.theme.control_background_color};
    }
    .DateRangePickerInput__disabled {
      background: ${props => chroma(props.theme.control_background_color).alpha(0.8).css()};
    }
    .DateRangePickerInput__withBorder {
      border-color: ${props => props.theme.control_border.color};
    }
    .DateRangePickerInput_arrow {
      color: ${props => props.theme.control_text};
    }
    .DateRangePickerInput_arrow_svg {
      fill: ${props => chroma(props.theme.control_border.color).darken().css()};
    }
    .DateRangePickerInput_clearDates_default:focus {
      background: ${props => props.theme.control_background_color};
    }
    .DateRangePickerInput_clearDates_default:hover {
      background: ${props => props.theme.control_background_color};
    }
    .DateRangePickerInput_clearDates_svg {
      fill: ${props => chroma(props.theme.control_border.color).darken().css()};
    }
    .DateRangePickerInput_calendarIcon_svg {
      fill: ${props => chroma(props.theme.control_border.color).darken().css()};
    }
    .DateRangePicker_picker {
      background-color: ${props => props.theme.control_background_color};
    }
    .DateRangePicker_picker__portal {
      background-color: rgba(0, 0, 0, 0.3);
    }
    .DateRangePicker_picker__fullScreenPortal {
      background-color: ${props => props.theme.control_background_color};
    }
    .DateRangePicker_closeButton:hover {
      color: ${props => chroma(props.theme.control_text).darken().css()};
    }
    .DateRangePicker_closeButton:focus {
      color: ${props => chroma(props.theme.control_text).darken().css()};
    }
    .DateRangePicker_closeButton_svg {
      fill: ${props => props.theme.control_background_color};
    }
`
export default themedStyles;
