/**
 * Logo Component
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {has} from 'ramda';
import dispatchResizeEvent from './../polyfill/dispatchResizeEvent';

import ResizeDetector from 'react-resize-detector';

/**
 * A component to display logos (images) within `ddk.Header` or `ddk.Sidebar`.
 */
export default class Logo extends Component {
    constructor(props) {
        super(props);
        this.handleResize = this.handleResize.bind(this);
        this.logoRef = React.createRef();
    }

    handleResize() {
        /*
         * forcing a re-render does not reflow/preserve
         * image aspect ratio — style assignment x2 needed
         * we do this to reflow logos when header dimensions
         * change via e.g. border-width in the theme editor
         */
        const logoElem = this.logoRef.current;
        if (logoElem && logoElem?.parentElement?.classList?.contains("layout-header")) {
            logoElem.style.height = "99%";
            // delay style assignment until after exec context is finished
            setTimeout(function(){
                logoElem.style.height = "100%";
            }, 0);
        }
    }

    render() {
        const { src, alt } = this.props;

        return (
            <div
                className={`layout-logo ${has('className', this.props) ? this.props.className : ''}`}
                style={this.props.style}
                ref={this.logoRef}
            >
                <img onLoad={dispatchResizeEvent} src={src} alt={alt} />
                <ResizeDetector
                    handleHeight={true}
                    handleWidth={true}
                    refreshMode="debounce"
                    refreshOptions={{trailing: true}}
                    refreshRate={50}
                    onResize={this.handleResize}
                    nodeType='span'
                />
            </div>
        )
    }
}

Logo.propTypes = {
    /**
     * The image URL. This can be local or remote.
     * For local images, place your image (e.g. `logo.png`) in the 
     * app's `assets/` folder and get the correct URL using 
     * `app.get_asset_url("logo.png")`.
     */
    src: PropTypes.string,

    /**
     * The alt attribute contains a textual description of the image,
     * which isn't mandatory but is incredibly useful for accessibility
     *   - screenreaders read this description out to their users so
     * they know what the image shows, and it is also displayed on the
     * page if the image can't be loaded for some reason.
     */
    alt: PropTypes.string,

    /**
     * Optional style on the div that wraps the underlying logo `img`
     */
    style: PropTypes.object,

    /**
     * className applied to the div that wraps the underlying logo `img`
     */
    className: PropTypes.string,
};
