import { css } from '../utils.js';
import chroma from 'chroma-js';

const themedStyles = css`
    .ddk-container.ddk-container .CalendarDay__default {
        background-color: ${props => props.theme.control_background_color}
    }

    .ddk-container.ddk-container .DateRangePicker__picker {
        background-color: ${props => props.theme.control_background_color};
    }

    .ddk-container.ddk-container .DayPickerKeyboardShortcuts_show {
        display: none;
    }

    .ddk-container.ddk-container .CalendarMonth {
        background: ${props => props.theme.control_background_color};
    }

    .ddk-container.ddk-container .DayPickerNavigation_button__default {
        background-color: ${props => props.theme.control_background_color};
    }

    .ddk-container.ddk-container .DateInput_fang {
        display: none;
    }

    .DateRangePickerInput_arrow svg {
        fill: ${props => props.theme.control_border.color}
    }

    .ddk-container.ddk-container .layout-header .SingleDatePickerInput,
    .ddk-container.ddk-container .layout-header .SingleDatePicker_picker,
    .ddk-container.ddk-container .layout-header .DateInput_input,
    .ddk-container.ddk-container .layout-header .DateInput_input__focused,
    .ddk-container.ddk-container .layout-header .SingleDatePicker_picker__fullScreenPortal,
    .ddk-container.ddk-container .layout-header .DateInput,
    .ddk-container.ddk-container .layout-header .DateRangePickerInput,
    .ddk-container.ddk-container .layout-header .DateRangePicker_picker,
    .ddk-container.ddk-container .layout-header .DateRangePicker_picker__fullScreenPortal,
    .ddk-container.ddk-container .layout-header .CalendarDay__default,
    .ddk-container.ddk-container .layout-header .DateRangePicker__picker,
    .ddk-container.ddk-container .layout-header .CalendarMonth,
    .ddk-container.ddk-container .layout-header .DayPickerNavigation_button__default {
        background-color: inherit;
    }

    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .SingleDatePickerInput,
    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .SingleDatePicker_picker,
    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .DateInput_input,
    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .DateInput_input__focused,
    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .SingleDatePicker_picker__fullScreenPortal,
    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .DateInput,
    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .DateRangePickerInput,
    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .DateRangePicker_picker,
    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .DateRangePicker_picker__fullScreenPortal,
    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .CalendarDay__default,
    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .DateRangePicker__picker,
    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .CalendarMonth,
    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .DayPickerNavigation_button__default {
        background-color: ${props => 
            (props.theme.header_background_color !== props.theme.background_content
            && props.theme.control_background_color === props.theme.background_content)
              ? 'inherit'
              : props.theme.control_background_color
        };
    }

    .ddk-container.ddk-container .layout-header .SingleDatePickerInput__disabled,
    .ddk-container.ddk-container .layout-header .DateInput_input__focused,
    .ddk-container.ddk-container .layout-header .DateInput__disabled,
    .ddk-container.ddk-container .layout-header .DateInput_input__disabled,
    .ddk-container.ddk-container .layout-header .DateRangePickerInput__disabled {
        opacity: 0.8;
    }

    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .SingleDatePickerInput__disabled,
    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .DateInput_input__focused,
    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .DateInput__disabled,
    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .DateInput_input__disabled,
    .ddk-container.ddk-container .layout-header:not([style*="background-color:"]) .DateRangePickerInput__disabled {
        background-color: ${props => 
            (props.theme.header_background_color !== props.theme.background_content
            && props.theme.control_background_color === props.theme.background_content)
              ? chroma(props.theme.header_background_color).alpha(0.3).css()
              : chroma(props.theme.control_background_color).alpha(0.3).css()
        };
    }
`
export default themedStyles;
