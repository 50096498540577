import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {has, is, merge, pick, path, propOr} from 'ramda';

/**
 * The PageFooter component allows you to customize the content and style
 * within the bottom margin of your page. A default PageFooter will be included
 * in each Page with the dimensions specified by the `page_margin.bottom`, which 
 * can be set globally in the `ddk.Report` component or in an individual
 * Page component.
 *
 * **Example Usage**
 * ```
 * app.layout = ddk.App([
 *     ddk.Page([
 *         ddk.Graph(
 *             figure = my_figure,
 *         ),
 *         # Place an italicized copyright message in the bottom margin
 *         ddk.PageFooter(
 *             'Copyright © 2020 Dash Enterprises, Inc. All Rights Reserved',
 *             style={'font-style': 'italic'}
 *         )
 *     ]),
 * ])
 * ```
 */
class PageFooter extends Component {
    render() {
        const {props} = this;

        const className = `ddk-page__footer ${propOr('', 'className', props)}`;
        const style = propOr({}, 'style', props);

        let pageNumber = null;
        if (propOr(path(['_page', '_display_page_number'], props), 'display_page_number', props)) {
            pageNumber = (
                <div
                    style={propOr({}, 'style_page_number', props)}
                    className={`
                        ddk-page__footer_number
                        ${propOr('', 'className_page_number', props)}
                    `}
                >
                    {`${props._page._page_number}`}
                </div>
            );
        }

        return (
            <footer
                style={style}
                className={className}
                {...pick(['id'], props)}
            >
                {pageNumber}
                {props.children}
            </footer>
        );
    }

}

PageFooter.propTypes = {
    /**
     * The list of components that are children of the PageFooter container.
     */
    children: PropTypes.any,

    /**
     * Display the page number for the associated Page in this PageFooter.
     */
    display_page_number: PropTypes.bool,

    /**
     * Overrides the default (inline) styles for the this component.
     */
    style: PropTypes.object,

    /**
     * Overrides the default (inline) styles for the this component's
     * page number child element.
     */
    style_page_number: PropTypes.object,

    /**
     * Optional user-defined CSS class for the PageFooter's page number
     * child component.
     */
    className_page_number: PropTypes.string,

    /**
     * Optional user-defined CSS class for the PageFooter container.
     */
    className: PropTypes.string,

    /**
     * The ID of this component, used to identify Dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    id: PropTypes.string
}

export default PageFooter;
