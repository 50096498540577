import {is, keys} from 'ramda';

export default function assignCssVariables(theme) {

    // returns a flattened theme object as CSS variables
    // theme{} keys are prefixed with '--'
    const cssVariables = keys(theme).reduce((o, k) => Object.assign(o,
            // flattens colorway[] and colorscale[]
            is(Array, theme[k]) ?
                theme[k].reduce((oo, kk, ii) => {
                    Object.assign(o,
                        {["--" + k + "-" + ii]: theme[k][ii]}
                    );
                }, {})            :
            {["--" + k]: theme[k] }
        ), {}
    )
    return cssVariables;
}
