import {
    __,
    clone,
    divide,
    has,
    includes,
    isEmpty,
    map,
    match,
    merge,
    mergeRight,
    path,
    pathEq,
    pluck,
    propOr,
    range,
    startsWith,
    transpose,
    type,
    zip,
    assocPath
} from 'ramda';
import chroma from 'chroma-js';
import { csvFormat } from 'd3-dsv';
import { saveAs } from 'file-saver/FileSaver';

import notifier from './plotly_notifier.js';
import { csvDownloadIcon } from './csvDownloadIcon.js'
import { isChildOf, templateLiteralDedent } from './utils';
import { CLASSNAMES } from './constants';

/*
 * Keys that we skip when crawling the figure to
 * find and replace color strings.
 * These keys have contents that are long (data arrays)
 * with no chance of color strings inside them.
 * This list was compiled by crawling the
 * schema for data_array & arrayOK keys.
 * That is, any key that can be a "numpy array" is a data key.
 * The only exceptions are:
 * - Keys with `color` in their name - which could be an array of
 *   numbers or it could be a color string. Since they can
 *   contain color strings, we have to crawl through them to
 *   check for var(--) variables.
 * - `source` & `geosjon` - this is a geojson object which are unusually large
 *   (e.g. choropleth of usa counties)
 */
import DATA_KEYS_FILE from './DATA_KEYS.json';
const DATA_KEYS = DATA_KEYS_FILE.DATA_KEYS;


// Data to include when downloading
const DL_KEYS = ['x', 'y', 'labels', 'values']

const getRow = (traces, rowNum) => traces.reduce((rowObj, trace, traceNum) => {
    if (trace.visible !== 'legendonly' && trace.visible !== false) {
        for (let i = 0; i < DL_KEYS.length; i++) {
            const k = DL_KEYS[i];
            if (trace[k] && trace[k].length > rowNum) {
                rowObj[(trace.name || ('trace.' + traceNum)) + '.' + k] = trace[k][rowNum];
            }
        }
    }
    return rowObj;
}, {})

function getRows(traces) {
    const rowArr = [];
    let row;
    for (let rowNum = 0; !isEmpty(row = getRow(traces, rowNum)); rowNum++) {
        rowArr.push(row);
    }
    return rowArr;
}

export function addModebarCsvDownloadButton(config) {
    if (config) {
        const dl_config = clone(config);

        // init modeBarButtons if it's an empty array
        dl_config.modeBarButtonsToAdd = dl_config.modeBarButtonsToAdd || [];

        const modebarCsvDownloadIcon = {
            name: 'Download CSV',
            icon: csvDownloadIcon,
            click: downloadCsv
        }
        dl_config.modeBarButtonsToAdd.push(modebarCsvDownloadIcon);
        return dl_config
    }
    return {}
}

function downloadCsv(gd) {
    const data = getRows(gd.data);

    if (data && data.length) {
        const csvData = csvFormat(data);

        var blob = new Blob([
            csvData
        ],
            { type: "text/csv;charset=utf-8" });

        notifier('Downloading trace data...', 'long');
        saveAs(blob, "myData.csv");
    } else {
        notifier('Failed to download csv data. Only basic chart types' +
            ' (scatter, bar, & pie) are supported for now.', 'long');
    }
}

/*
 * Abstract function to crawl a figure and call a function on the crawlled keys.
 * `skipKeys` allows the crawler to skip certain keys, like data keys,
 * that are unecessary
 */
function crawl(figureObj, applyFunctionOnKey, skipKeys, path=[]) {
    if (type(figureObj) === 'Object') {
        for (const key in figureObj) {
            if (includes(key, skipKeys)) {
                continue;
            }

            path.push(key);
            applyFunctionOnKey(figureObj, key, path);
            crawl(figureObj[key], applyFunctionOnKey, skipKeys, path);
            path.pop()
        }
    } else if (type(figureObj) === 'Array') {
        for (let i = 0; i < figureObj.length; i++) {
            path.push(i);
            applyFunctionOnKey(figureObj, i, path);
            crawl(figureObj[i], applyFunctionOnKey, skipKeys, path);
            path.pop();
        }
    }
}

function warnOnBadThemeVarSyntax(themeVar) {
    throw new Error(
        templateLiteralDedent(`
            You attempted to reference a theme variable,
            but the syntax appears malformed.
            Theme variables should be one of
            (${
            JSON.stringify(
                Object.keys(window.dashTheme),
                null,
                2
            ).replace(/color(\w+)/g, 'color$1-N')
            }),
            along with the prefix 'var(--' and the suffix ')'.

            Discrete color(way|scale) swatch references should be
            0-indexed integers. colorscale swatch references
            should be single-digit indices (i.e. 0-9).

            For example, 'var(--accent)' or 'var(--colorway-2)'.

            The malformed variable you defined was
            ${themeVar}.
        `)
    );
}


/*
 * Clone the figure but skip the DATA_KEYS, so that we aren't
 * copying huge arrays (which could be slow)
 */
export function cloneFigureWithoutDataKeys(figure) {
    const newFigure = Object.assign({}, figure);
    crawl(
        newFigure,
        (parent, item) => {
            if (type(parent[item]) === 'Object') {
                parent[item] = Object.assign({}, parent[item]);
            } else if (type(parent[item]) === 'Array') {
                parent[item] = parent[item].slice(0);
            }
        },
        DATA_KEYS
    );
    return newFigure;
}

/**
 * Check if we're given a 2D array in dashTheme.colorscale;
 * if not, construct one with interpolation
 */
export function normalizeColorscale(colorscale) {
    return !Array.isArray(colorscale[0]) ? zip(
        map(divide(__, colorscale.length - 1), range(0, colorscale.length + 1)),
        colorscale
    ) : colorscale.map(arr => [parseFloat(arr[0], 10), arr[1]])
}

export function unthemeGraph(themed, unthemed) {
    let clone = themed;
    crawl(
        unthemed,
        (parent, item, _path) => {
            const unthemedValue = parent[item];

            if (type(unthemedValue) === 'String' && startsWith('var(--', unthemedValue)) {
                clone = assocPath(_path, unthemedValue, clone)
            }
        },
        DATA_KEYS
    )

    return clone;
}

export function themeGraph(figure, gd) {
    if (!window.dashTheme) {
        return figure;
    }

    const themeVarRegex = /^var\(--(\w+(-\d+)?)\)$/
    const colorRegex = /^(colorway|colorscale)-(\d+)$/;

    const newFigure = Object.assign({}, figure);
    crawl(
        newFigure,
        (parent, item) => {
            if (type(parent[item]) === 'String' &&
                startsWith('var(--', parent[item])) {
                const themeVariable = match(themeVarRegex, parent[item])[1];
                if (!themeVariable) {
                    warnOnBadThemeVarSyntax(parent[item]);
                }
                const colorRegexGroups = match(colorRegex, themeVariable);
                if (colorRegexGroups[1] === 'colorway') {
                    const colorway = window.dashTheme.colorway;
                    parent[item] = colorway[parseFloat(colorRegexGroups[2]) % colorway.length]
                }
                else if (colorRegexGroups[1] === 'colorscale') {
                    const index = parseFloat(colorRegexGroups[2]);
                    if (index >= 10) {
                        throw new Error(
                            templateLiteralDedent(`
                                You attempted to reference a theme colorscale variable,
                                but discrete colorscale swatch references should be
                                0-indexed, single digit integers (i.e. 0-9).

                                For example, 'var(--colorscale-9)'.

                                The malformed variable you defined was
                                ${parent[item]}.
                            `)
                        );
                    }
                    const colorscale = normalizeColorscale(window.dashTheme.colorscale);
                    if (colorscale.length <= 1) {
                        throw new Error(
                            templateLiteralDedent(`
                                Colorscales need to be lists of at least two items.
                                The malformed colorscale you defined was
                                ${window.dashTheme.colorscale}.
                            `)
                        );
                    }
                    let distColorscale;
                    // We're dealing with a 2-dimensional array
                    if (Array.isArray(colorscale[0])) {
                        const transposedColorscale = transpose(colorscale);
                        /**
                         * the `chroma.js` [`scale.domain`](https://gka.github.io/chroma.js/#scale-domain)
                         * function accepts a different format
                         * (e.g. `chroma.scale(['yellow', 'lightgreen', '008ae5']).domain([0,0.25,1])`)
                         * than Plotly (e.g. `[[0, 'yellow'], [0.25, 'lightgreen'], [1, '#008ae5']]`),
                         * hence the need to `R.transpose` the provided scale
                         *
                         * We use the default Chroma 'rgb' scale interpolation to match `plotly.js`
                         */
                        distColorscale = chroma.scale(transposedColorscale[1])
                            .domain(transposedColorscale[0])
                    } else {
                        distColorscale = chroma.scale(colorscale);
                    }
                    parent[item] = distColorscale(index / 9).hex();
                } else {
                    if (!window.dashTheme[themeVariable]) {
                        warnOnBadThemeVarSyntax(parent[item]);
                    }
                    parent[item] = window.dashTheme[themeVariable]
                }
            }
        },
        DATA_KEYS
    );

    const {
        colorway,
        colorscale,
        accent,
    } = window.dashTheme

    const gridcolor = window.dashTheme.border;
    const accentPositive = window.dashTheme.accent_positive;
    const accentNegative = window.dashTheme.accent_negative;

    let bgcolor;
    let bgcolorAlt;
    let textColor;
    let font;
    let fontSize;

    if (isChildOf(gd, CLASSNAMES['ddk-page'])) {
        bgcolor = window.dashTheme.report_background_content;
        bgcolorAlt = window.dashTheme.report_background_page;
        textColor = window.dashTheme.report_text;
        font = window.dashTheme.report_font_family;
        fontSize = window.dashTheme.report_font_size;
    } else {
        bgcolor = window.dashTheme.background_content;
        bgcolorAlt = window.dashTheme.background_page;
        textColor = window.dashTheme.body_text;
        font = window.dashTheme.font_family;
        fontSize = window.dashTheme.font_size;
    }

    const axis = () => ({
        'gridcolor': gridcolor,
        'zerolinecolor': gridcolor,
        'tickcolor': gridcolor,
        'linecolor': gridcolor,
        'automargin': true,
        'cliponaxis': false,
        'title': { 'standoff': 15 }
    });

    const colorscaleProps = {
        // TODO: check that these properties show up soon via https://github.com/plotly/plotly.js/pull/4470
        // This is now in 1.52.0
        'colorscale': normalizeColorscale(colorscale),
        'colorbar': {
            'ypad': 0,
            'outlinewidth': 0,
            'title': { 'side': 'right' }
        }
    };
    if (!has('layout', newFigure)) {
        newFigure.layout = {};
    }

    const isMapbox = includes(
        'scattermapbox', pluck('type', propOr([], 'data', newFigure))
    );

    // the keys of this object should stay in sync with ./TRACE_TYPES.json
    newFigure.layout.template = {
        'data': {
            'scatter': [{
                opacity: 0.8,
                marker: merge(colorscaleProps, { size: 8 })
            }],
            'scattergl': [{
                opacity: 0.8,
                marker: merge(colorscaleProps, { size: 8 })
            }],
            'pointcloud': [{
                opacity: 0.8,
                marker: merge(colorscaleProps, { size: 8 })
            }],
            'bar': [{}],
            'barpolar': [{}],

            'heatmap': [colorscaleProps],
            'heatmapgl': [colorscaleProps],
            'contour': [colorscaleProps],
            'volume': [colorscaleProps],
            'histogram2d': [colorscaleProps],
            'histogram2dcontour': [colorscaleProps],

            'indicator': [{
                'delta': {
                    'increasing': {
                        'color': accentPositive
                    },
                    'decreasing': {
                        'color': accentNegative
                    },
                    'font': {
                        'family': font,
                        'size': fontSize
                    }
                },
            }],

            'image': [{}],

            'pie': [{}],

            'box': [{}],
            'violin': [{}],
            'histogram': [{}],

            'scatter3d': [{ marker: colorscaleProps }],
            'mesh3d': [merge(colorscaleProps, { flatshading: true })],
            'surface': [colorscaleProps],
            'isosurface': [colorscaleProps],
            'cone': [colorscaleProps],
            'streamtube': [colorscaleProps],
            'funnel': [{}],
            'funnelarea': [{}],

            'sunburst': [{}],
            'icicle': [{}],

            'choropleth': [colorscaleProps],
            'choroplethmapbox': [colorscaleProps],
            'densitymapbox': [colorscaleProps],
            'scattergeo': [{ marker: colorscaleProps }],
            'scattermapbox': [{ marker: colorscaleProps }],
            // splom = ScatterPLOtMatrix
            'splom': [{ marker: colorscaleProps }],

            'candlestick': [{
                'decreasing': {
                    'line': { 'color': accentNegative }
                },
                'increasing': {
                    'line': { 'color': accentPositive }
                },
            }],
            'ohlc': [{
                'decreasing': {
                    'line': { 'color': accentNegative }
                },
                'increasing': {
                    'line': { 'color': accentPositive }
                },
            }],
            'waterfall': [{
                'decreasing': {
                    'marker': { 'color': accentNegative }
                },
                'increasing': {
                    'marker': { 'color': accentPositive }
                },
                'totals': {
                    'marker': { 'color': accent }
                },
            }],
            'treemap': [{ marker: colorscaleProps }],

            'scatterternary': [{ marker: colorscaleProps }],
            'scatterpolar': [{ marker: colorscaleProps }],
            'scatterpolargl': [{ marker: colorscaleProps }],

            'parcoords': [{}],
            'parcats': [{ line: colorscaleProps }],
            'sankey': [{
                'link': {
                    'color': bgcolorAlt
                }
            }],

            'table': [{
                'header': {
                    'fill': { 'color': bgcolorAlt },
                    'font': { 'family': font, 'size': fontSize, 'color': textColor },
                    'line': { 'color': gridcolor }
                },
                'cells': {
                    'fill': { 'color': bgcolor },
                    'font': { 'family': font, 'size': fontSize, 'color': textColor },
                    'line': { 'color': gridcolor }
                }
            }],

            'carpet': [{
                'aaxis': merge(
                    axis(), {
                    gridcolor: colorway[0],
                    linecolor: gridcolor,
                    tickcolor: gridcolor,
                    minorgridcolor: gridcolor,
                    startlinecolor: gridcolor,
                    endlinecolor: gridcolor,
                }
                ),
                'baxis': merge(
                    axis(), {
                    gridcolor: colorway[0],
                    linecolor: gridcolor,
                    tickcolor: gridcolor,
                    minorgridcolor: gridcolor,
                    startlinecolor: gridcolor,
                    endlinecolor: gridcolor,
                }
                ),
                'font': {
                    'color': textColor,
                    'family': font
                }
            }],
            'contourcarpet': [colorscaleProps],
            'scattercarpet': [{ marker: colorscaleProps }],
        },
        'layout': {
            'colorway': colorway,
            'paper_bgcolor': bgcolor,
            'plot_bgcolor': bgcolor,

            /* margin should autoexpand to just contain the text.
             * give a little extra margin to compensate for the hover labels
             * and when automargin doesn't work.
             * scattermapbox doesn't have axes, so we're able to shrink
             * the margins down to 0.
             */
            'margin': isMapbox ? { 'l': 0, 'r': 0, 'b': 0, 't': 0 } : ({
                'l': 15,
                'r': 0,
                'b': 30,
                't': newFigure.layout.title ? 60 : 15
            }),

            // vertical modebar so that it doesn't interfere with the legend
            'modebar': { 'orientation': 'v' },

            'legend': {
                'x': 1,
                'xanchor': 'right',
                'y': 1,
                'yanchor': 'top',
                'orientation': 'h',
                'bgcolor': chroma(bgcolor).alpha(0.75).css()
            },

            'hoverlabel': {
                'bgcolor': bgcolor,
                'bordercolor': gridcolor,
                'font': {
                    'color': textColor,
                    'family': font
                }
            },

            'font': {
                'color': textColor,
                'family': font
            },

            'xaxis': axis(),
            'yaxis': axis(),
            'scene': {
                'xaxis': axis(),
                'yaxis': axis(),
                'zaxis': axis(),
            },
            'polar': {
                'radialaxis': axis(),
                'angularaxis': axis(),
                'bgcolor': bgcolor
            },
            'ternary': {
                'aaxis': axis(),
                'baxis': axis(),
                'caxis': axis(),
                'bgcolor': bgcolor
            },
            'geo': mergeRight({
                'lataxis': axis(),
                'lonaxis': axis(),
                'bgcolor': bgcolor,
                'showframe': false,
                /*
                 * USA center except for orthographic
                 * https://github.com/plotly/dash-design-kit/issues/674
                 */
            }, pathEq(['layout', 'geo', 'projection', 'type'], 'orthographic', newFigure) ?
                {
                    'projection': {
                        'rotation': {
                            'lat': 40,
                            'lon': -100,
                        }
                    }
                }
                :
                {
                    'center': {
                        'lat': 39.8097343,
                        'lon': -98.5556199
                    }
                }
            ),
            'mapbox': {
                'style': path(['layout', 'mapbox', 'accesstoken'], newFigure)
                    ? chroma(bgcolor).luminance() > 0.3 ? 'light' : 'dark'
                    : chroma(bgcolor).luminance() > 0.3 ? 'open-street-map' : 'carto-darkmatter',
                'center': {
                    'lat': 39.8097343,
                    'lon': -98.5556199
                }
            }
        }
    }

    if (includes('markers', pluck('mode', propOr([], 'data', newFigure)))) {
        newFigure.layout.template.layout.hovermode = 'closest';
    }

    return newFigure;
}

/* FullScreen and Modal graphs */

export const isFullWidthChildren = (parent, children) => {
    const max = (a, b) => {
        const diff1 = parent.clientWidth -
            a.clientWidth
        const diff2 = parent.clientWidth -
            b.clientWidth

        return (diff1 > diff2) ? diff1 : diff2
    };
    /* 30px = liberal magic # accounting for
     margin, padding etc. */
    return (children.length <= 1) || children.reduce(max) <= 30;
}

export function setExpandedGraphDimensions(container) {
    const element = container.element;
    const isCard = element.classList.contains("card--content");
    if (isCard) {
        const graphContainers = container.graphContainers;
        const fullWidthGraphs = isFullWidthChildren(element, Array.from(graphContainers));
        Array.from(graphContainers).map(graphContainer => {
            if (graphContainers.length <= 3) {
                const cardHeaderFooterHeights =
                    Array.from(element.getElementsByClassName('card-header')).concat(
                        Array.from(element.getElementsByClassName('card-footer')))
                        .reduce((sum, elem) => sum + elem.scrollHeight, 0)
                if (fullWidthGraphs) {
                    /* Fit all full width graphs within the screen height */
                    if (graphContainer.style.height === "") {
                        graphContainer.style.height = `calc((100% - ${cardHeaderFooterHeights}px) / ${graphContainers.length})`;
                    }
                } else {
                    /* Stretch each graph to the screen height */
                    if (graphContainer.style.height === "") {
                        graphContainer.style.height = `calc(100% - ${cardHeaderFooterHeights}px)`;
                    }
                }
            }
        });
    }
}

export function saveGraphDimensions(container, cb) {
    const element = container.element;
    const origGraphChildrenStyles = [];
    let graphContainers = element.classList.contains('dash-graph')
        ? [element] // the container is the Graph element itself
        : element.getElementsByClassName('dash-graph');
    if (graphContainers.length > 0) {
        graphContainers = Array.from(graphContainers);
        graphContainers = graphContainers.map(graphContainer => {
            const graphChild = graphContainer.getElementsByClassName('svg-container')[0];
            graphContainer.style.oldWidth = graphContainer.style.width;
            graphContainer.style.oldHeight = graphContainer.style.height;
            // unfortunately saving this on graphChild itself leads to inconsistencies
            origGraphChildrenStyles.push(graphChild.style.cssText);

            return graphContainer;
        });
    }
    container.graphContainers = graphContainers;
    container.origGraphChildrenStyles = origGraphChildrenStyles;
    if (cb) {
        cb();
    }
}
