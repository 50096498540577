import React, {PureComponent, useCallback} from 'react';
import PropTypes from 'prop-types';
import {propOr, mergeRight, includes} from 'ramda';

/* DDK React components */
import FullScreen from './FullScreen.react';
import Modal from './Modal.react';
import CopyText from '../_CopyText.react';

import {CardContext} from './Card.react'

/* Card icons */
import IconCardModal from './../icons/icon-card-modal.svg';
import IconCardFullscreen from './../icons/icon-card-fullscreen.svg';
import IconCardCopy from './../icons/icon-card-copy.svg';

class CardHeaderInner extends React.Component {
    constructor(props) {
        super(props);
        if (this.props.copy || this.props.fullscreen || this.props.modal) {
            this.parentCard = this.props.card_ref().current;
            if (this.props.modal) {
                this.expand_modal_icon = (
                    <Modal
                        cardRef={this.parentCard}
                        width={(this.props.modal_config && this.props.modal_config.width) ? this.props.modal_config.width : null}
                        height={(this.props.modal_config && this.props.modal_config.height) ? this.props.modal_config.height : null}
                        modal_container_className={this.props.modal_container_className}
                    >
                        <IconCardModal/>
                    </Modal>
                )
            }
        }
    }

    render() {
        /* card components
         * these are declared but only initialized if relevant arg is set
         */
        const {
            id,
            children,
            title,
            modal,
            modal_config,
            fullscreen,
            copy,
            margin,
            padding,
            background_color,
            border_width,
            border_style,
            border_color,
            border_radius,
            box_shadow
        } = this.props;

        /* merge conditional styles from props */
        const style = mergeRight(
            {
                padding: padding,
                margin: margin,
                backgroundColor: background_color,
                borderWidth: border_width,
                borderStyle: border_style,
                borderColor: border_color,
                borderRadius: border_radius,
                boxShadow: box_shadow,
            },
            propOr({}, 'style', this.props)
       );

        const childType = children?.props?._dashprivate_layout?.type

        const headerControlContainerStyle = {
            /*
             * Slider, RangeSlider, and Dropdown
             * don't have a native width and thus stretch
             * to fill space, with a min 50% width so they wrap
             * instead of shrinking on small widths
             * Other items are given "0 1 auto" = browser/spec default.
             */
            flex: includes(childType, ["Slider", "RangeSlider", "Dropdown"]) ? "1 0 50%" : "0 1 auto",
            minWidth: includes(childType, ["Slider", "RangeSlider", "Dropdown"]) ? "100px" : "",
            /*
             * margin-left: auto = floats items to right. Default = 0
             */
            marginLeft: !title ? 'auto' : 0
        }

        return (
            <header
                className={`card-header ${propOr('', 'className', this.props)}`}
                id={id}
                style={mergeRight({
                        justifyContent: title
                          ? 'space-between'
                          : 'flex-start' // default
                    },
                    style
                )}
            >
                {this.props.title &&
                    <b
                        className="card-header--title"
                    >
                        {title}
                    </b>
                }
                {children &&
                    <div
                        style={headerControlContainerStyle}
                        className='control-in-card_container'
                     >
                        {children}
                    </div>
                }
                {(copy || fullscreen || modal) &&
                    <div className="header-expand-container">
                       {copy &&
                           <CopyText
                               cardRef={this.parentCard}
                           >
                               <IconCardCopy/>
                           </CopyText>
                       }
                       {fullscreen &&
                           <FullScreen
                               cardRef={this.parentCard}
                           >
                                <IconCardFullscreen/>
                           </FullScreen>
                       }
                       {modal && this.expand_modal_icon}
                    </div>
                }
            </header>
        )
    }
}

// wrapper that allows Context usage in React lifecycle
// from: https://github.com/facebook/react/issues/12397#issuecomment-375501574
// this could be a functional component but for this.forceUpdate(), doesn't work with Dash x hooks

/**
 * A header component designed to be the first child of a `Card` or `ControlCard`.
 * Takes `title` (string), `fullscreen`, and `modal` (bool) arguments, in addition
 * to `children[]` designed to contain Dash Core Components (DCC) controls
 * (e.g. `dcc.Dropdown`, `dcc.DatePickerRange`), or other components meant to be
 * displayed in the bottom of the card.
 *
 * Note that placing a string in `title` and a string in `children` will have a similar effect.
 * The only differences are that the title string will be bolded and that the children
 * property can include other components like controls (not just strings).
 *
 * **Example Usage**
 * ```
 * app.layout = ddk.App([
 *     ddk.Card(width=70,
 *         children=[
 *             # Allow the card to be expanded to fullscreen or modal
 *             # Set the expanded modal dimensions to 80%
 *             ddk.CardHeader(
 *                dcc.DatePickerRange(
 *                    start_date=datetime.datetime(2019, 1, 1),
 *                    end_date=datetime.datetime(2020, 8, 1)
 *                ),
 *                title='String card title',
 *                modal=True, fullscreen=True,
 *                modal_config={'width': 80, 'height': 80}),
 *             ddk.Graph(id="sample-graph-id", figure={
 *                  'data': [{
 *                    'x': [1, 2, 3, 4],
 *                    'y': [4, 1, 6, 9],
 *                    'line': {'shape': 'spline'}
 *                 }]
 *             }),
 *         ],
 *     ),
 * ])
 * ```
 */
class CardHeader extends React.Component {

    constructor(props) {
        super(props);

        this.needsUpdate = this.needsUpdate.bind(this);
    }

    needsUpdate(cardContext) {
        let {
            fullscreen,
            modal,
            copy
        } = this.props;

        this.parentCardRef = cardContext.getRef().current;

        return ((fullscreen || modal || copy) && !cardContext.getRef().current)
    }

    render() {

        const props = this.props;
        return (
            <CardContext.Consumer>
                {(cardContext) => {
                    return (
                        <React.Fragment>
                            <React.Fragment key={(this.needsUpdate(cardContext) ? this.forceUpdate() : '')} />
                            { !this.needsUpdate(cardContext) && <CardHeaderInner {...props} card_ref={cardContext.getRef} /> }
                        </React.Fragment>
                    )
                }}
            </CardContext.Consumer>
        )
    }
};


CardHeaderInner.defaultProps = {
}

CardHeader.defaultProps = {
}

CardHeader.propTypes = {
    /**
     * The ID of this component, used to identify Dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    id: PropTypes.string,

    /**
     * The list of components that are children of the CardHeader container.
     * These children should be Dash Core Components (DCC) controls
     * (e.g. `dcc.Dropdown`, `dcc.DatePickerRange`),
     * or other components meant to be displayed in the top of the card.
     */
    children: PropTypes.node,

    /**
     * string or Dash component; optional
     */
    title: PropTypes.string,

    /**
     * Displays an icon that allows the card to be expanded to a modal.
     */
    modal: PropTypes.bool,

    /**
     * The optional className of the modal container if modal=True
     */
    modal_container_className: PropTypes.string,

    /**
     * Displays an icon that allows the card's innerText to be copied
     * to the clipboard.
     */
    copy: PropTypes.bool,

    /**
     * Object that takes 'width' and 'height' arguments to define modal dimensions
     * Width or height can either be a string or a num N that gets converted to N%
     */
    modal_config: PropTypes.exact({
        width: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        height: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ])
    }),

    /**
     * Displays an icon that allows the card to be expanded to a modal.
     */
    fullscreen: PropTypes.bool,

    /**
     * Space (in pixels) surrounding the CardHeader.
     * Overrides theme.card_header_margin.
     */
    margin: PropTypes.number,

    /**
     * Space (in pixels) on the inside of the CardHeader, between the border
     * and the edge of the content.
     * Overrides theme.card_header_padding.
     */
    padding: PropTypes.number,

    /**
     * The background color applied to the CardHeader. Overrides theme.card_header_background_color.
     */
    background_color: PropTypes.string,

    /**
     * The box shadow(s) applied to the CardHeader. Overrides theme.card_header_box_shadow.
     */
    box_shadow: PropTypes.string,

    /**
     * The border width applied to the CardHeader. Overrides theme.card_header_border.width.
     */
    border_width: PropTypes.string,

    /**
     * The border style applied to the CardHeader. Overrides theme.card_header_border.style.
     */
    border_style: PropTypes.string,

    /**
     * The border color applied to the CardHeader. Overrides theme.card_header_border.color.
     */
    border_color: PropTypes.string,

    /**
     * The border radius applied to the CardHeader. Overrides theme.card_header_border.radius.
     */
    border_radius: PropTypes.string,

    /**
     * Optional additional CSS styles.
     * - If `width`, `padding`, or `margin` are supplied within `style`,
     * then this will override the component-level `width`, `padding`, or `margin`.
     */
    style: PropTypes.object,

    /**
     * Optional user-defined CSS class for the CardHeader container.
     */
    className: PropTypes.string
}

CardHeaderInner.propTypes = {
    /**
     * The ID of this component, used to identify Dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    id: PropTypes.string,

    /**
     * The list of components that are children of the CardHeader container.
     */
    children: PropTypes.node,

    /**
     * string or Dash component; optional
     */
    title: PropTypes.string,

    /**
     * Displays an icon that allows the card to be expanded to a modal.
     */
    modal: PropTypes.bool,

    /**
     * The optional className of the modal container if modal=True
     */
    modal_container_className: PropTypes.string,

    /**
     * Displays an icon that allows the card's innerText to be copied
     * to the clipboard.
     */
    copy: PropTypes.bool,

    card_context: PropTypes.any,

    /**
     * Object that takes 'width' and 'height' arguments to define modal dimensions
     * Width or height can either be a string or a num N that gets converted to N%
     */
    modal_config: PropTypes.shape({
        width: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        height: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ])
    }),

    box_shadow: PropTypes.string,

    card_border: PropTypes.string,

    card_border_width: PropTypes.string,

    card_border_style: PropTypes.string,

    card_border_color: PropTypes.string,

    card_border_radius: PropTypes.string,

    /**
     * Displays an icon that allows the card to be expanded to a modal.
     */
    fullscreen: PropTypes.bool,

    /**
     * Optional additional CSS styles.
     * - If `width`, `padding`, or `margin` are supplied within `style`,
     * then this will override the component-level `width`, `padding`, or `margin`.
     */
    style: PropTypes.object,

    /**
     * Optional user-defined CSS class for the CardHeader container.
     */
    className: PropTypes.string
}

export default CardHeader;
