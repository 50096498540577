import { css } from '../utils.js';
import chroma from 'chroma-js';
import tinycolor from 'tinycolor2';

const getReadable = (background, text) => {
    return (tinycolor.isReadable(background, text)) ? text :
        tinycolor.mostReadable(
            background,
            [text, tinycolor(text).darken(20), tinycolor(text).lighten(20)],
        ).toHexString();
}

const themedStyles = css`
/* jmblog.github.com/color-themes-for-google-code-highlightjs */

.ddk-container .hljs {
    background-color: ${props => props.theme.background_content};
    color: ${props => props.theme.body_text};
}

/* comments */
.ddk-container .hljs-comment,
.ddk-container .hljs-quote {
  color: ${props => getReadable(props.theme.background_content, '#898989')};
}

/* vars, tags, selectors... */
.ddk-container .hljs-variable,
.ddk-container .hljs-template-variable,
.ddk-container .hljs-tag,
.ddk-container .hljs-name,
.ddk-container .hljs-selector-id,
.ddk-container .hljs-selector-class,
.ddk-container .hljs-regexp,
.ddk-container .hljs-deletion {
  color: ${props => getReadable(props.theme.background_content, props.theme.accent_negative)};
}

/* numbers, literals, types... */
.ddk-container .hljs-number,
.ddk-container .hljs-built_in,
.ddk-container .hljs-builtin-name,
.ddk-container .hljs-literal,
.ddk-container .hljs-type,
.ddk-container .hljs-params,
.ddk-container .hljs-meta,
.ddk-container .hljs-link {
  color: ${props => getReadable(props.theme.background_content, props.theme.colorway[4])};
}

/* attributes */
.ddk-container .hljs-attribute {
  color: ${props => getReadable(props.theme.background_content, props.theme.colorway[3])};
}

/* strings... */
.ddk-container .hljs-string,
.ddk-container .hljs-symbol,
.ddk-container .hljs-bullet,
.ddk-container .hljs-addition {
  color: ${props => getReadable(props.theme.background_content, props.theme.accent)};
}

.ddk-container .hljs-title,
.ddk-container .hljs-section {
  color: ${props => getReadable(props.theme.background_content, props.theme.colorway[1])};
}

.ddk-container .hljs-keyword,
.ddk-container .hljs-selector-tag {
  color: ${props => getReadable(props.theme.background_content, props.theme.colorway[2])};
}
`
export default themedStyles;
