import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {pick, propOr} from 'ramda';

/**
 * The PageHeader component allows you to customize the content and style
 * within the top margin of your page. A default PageHeader will be included
 * in each Page with the dimensions specified by the `page_margin.top`, which 
 * can be set globally in the `ddk.Report` component or in an individual
 * Page component.
 *
 * **Example Usage**
 * ```
 * app.layout = ddk.App([
 *     ddk.Page([
 *         ddk.PageHeader([
 *             # Place a logo in the top margin
 *             ddk.Logo(src='https://dash.plotly.com/assets/images/logo-plotly.png'),
 *         ]),
 *         ddk.Graph(
 *             figure = my_figure,
 *         ),
 *     ]),
 * ])
 * ```
 */
function PageHeader(props) {
    const className = `ddk-page__header ${propOr('', 'className', props)}`;

    return (
        <header className={className}
            {...pick(['id'], props)}
            style={props.style}
        >
            {props.children}
        </header>
    );
}

PageHeader.propTypes = {
    /**
     * The list of components that are children of the PageHeader container.
     */
    children: PropTypes.any,

    /**
     * Overrides the default (inline) styles for the this component.
     */
    style: PropTypes.object,

    /**
     * Optional user-defined CSS class for the PageHeader container.
     */
    className: PropTypes.string,

    /**
     * The ID of this component, used to identify Dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    id: PropTypes.string
}

export default PageHeader;
