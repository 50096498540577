import { css } from '../utils.js';
import tinycolor from 'tinycolor2';

const themedStyles = css`

.templates_sample-apps .tab-parent .templates-tabs--tab::before {
    background-color: ${props => props.theme.accent}
}

.templates_sample-apps .tab-parent .templates-tabs .tab.templates-tabs--tab:hover,
.templates_sample-apps .tab-parent .templates-tabs .tab.tab--selected.templates-tabs--tab {
    color: ${props =>
                /* More liberal interpretation of 'readability' to skew towards '#FFFFFF' */
                tinycolor.readability(props.theme.accent, '#FFFFFF') >= 2.5 ? '#FFFFFF' :
                tinycolor.mostReadable(
                    props.theme.accent,
                    ['#FFFFFF'],
                    {includeFallbackColors:true, level:"AA", size: "large"}
                ).toHexString()
}

`
export default themedStyles;
