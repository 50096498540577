import { css } from '../utils.js';
import * as R from 'ramda';

const themedStyles = css`
    .ddk-container .layout-header {
        background-color: ${props => props.theme.background_content};
    }

    .ddk-container .layout-header .layout-title {
        text-transform: ${props => props.theme.title_capitalization};
    }

    .layout-menu li {
        display: flex;
        align-items: center;
    }

    .layout-menu li a {
        width: 100%;
        height: 100%;
        text-align: center;
        padding: 8px;
        text-decoration: none;
        display: flex;
        justify-content:center;
        align-content:center;
        flex-direction:column; /* column | row */
    }

    nav#menu[data-collapsed="true"] ul {
        z-index: 999;
        background-color: ${props => props.theme.background_page};
    }

`
export default themedStyles;
