import { css } from '../utils.js';
import tinycolor from 'tinycolor2';

const themed = key => props => props.theme[key];

// "Minor Third" scalar
// from https://type-scale.com
const FONT_SCALE = 1.2;

const themedStyles = css`
    .ddk-container.ddk-container blockquote {
      border-left-color: ${props => props.theme.border};
    }
    .ddk-container.ddk-container a {
      color: ${props => props.theme.accent}; }
    .ddk-container.ddk-container a:hover {
      color: ${props => props.theme.accent}; }
    .ddk-container.ddk-container button:not(.btn):not(.edit-theme-button):not([type="button"]),
    .ddk-container.ddk-container input[type="submit"],
    .ddk-container.ddk-container input[type="reset"],
    .ddk-container.ddk-container input[type="button"] {
      color            : ${props => props.theme.button_text};
      border-style: solid;
      border-width: ${props => props.theme.button_border.width};
      border-color: ${props => props.theme.button_border.color};
      border-radius: ${props => props.theme.button_border.radius};
      background-color : ${props => props.theme.button_background_color};
      text-transform: ${props => props.theme.button_capitalization};
      font-family: ${props =>
        props.theme.button_capitalization !== 'uppercase'
          ? props.theme.font_family
          : 'sans-serif'
      };
      transition       : all 0.2s ease;
    }

    .ddk-container.ddk-container input::placeholder {
        color: ${props => props.theme.body_text};
        opacity: 0.6;
    }

    .ddk-container.ddk-container button:not(.btn):not(.edit-theme-button):not([type="button"]):active,
    .ddk-container.ddk-container input[type="submit"]:active,
    .ddk-container.ddk-container input[type="reset"]:active,
    .ddk-container.ddk-container input[type="button"]:active {
        box-shadow: none;
    }
    .ddk-container.ddk-container .button:not(.btn):not(.edit-theme-button):not([type="button"]):hover:enabled,
    .ddk-container.ddk-container button:not(.btn):not(.edit-theme-button):not([type="button"]):hover:enabled,
    .ddk-container.ddk-container input[type="submit"]:hover:enabled,
    .ddk-container.ddk-container input[type="reset"]:hover:enabled,
    .ddk-container.ddk-container input[type="button"]:hover:enabled {
        color: ${props => 
            props.theme.button_background_color === props.theme.background_content
              ? tinycolor(props.theme.button_text).isLight()
                ? tinycolor(props.theme.button_text).darken(20)
                : tinycolor(props.theme.button_text).lighten(20)
              : ''
        };
        border-color: ${props => 
            (props.theme.button_background_color === props.theme.background_content
            || props.theme.button_background_color === props.theme.button_border.color)
              ? tinycolor(props.theme.button_border.color).isLight()
                ? tinycolor(props.theme.button_border.color).darken(15)
                : tinycolor(props.theme.button_border.color).lighten(15)
              : ''
        };
        background-color: ${props => 
            props.theme.button_background_color !== props.theme.background_content
              ? tinycolor(props.theme.button_background_color).isLight()
                ? tinycolor(props.theme.button_background_color).darken(15)
                : tinycolor(props.theme.button_background_color).lighten(15)
              : ''
        };
    }

    .ddk-container.ddk-container .button:not(.btn):not(.edit-theme-button):not([type="button"]):focus,
    .ddk-container.ddk-container button:not(.btn):not(.edit-theme-button):not([type="button"]):focus,
    .ddk-container.ddk-container input[type="submit"]:focus,
    .ddk-container.ddk-container input[type="reset"]:focus,
    .ddk-container.ddk-container input[type="button"]:focus {
        outline: none;
    }

    .ddk-container.ddk-container .button:not(.btn):not(.edit-theme-button):not([type="button"]):disabled,
    .ddk-container.ddk-container button:not(.btn):not(.edit-theme-button):not([type="button"]):disabled,
    .ddk-container.ddk-container input[type="submit"]:disabled,
    .ddk-container.ddk-container input[type="reset"]:disabled,
    .ddk-container.ddk-container input[type="button"]:disabled {
        opacity: 0.5;
    }

    .ddk-container.ddk-container .button:not(.btn):not(.edit-theme-button):not([type="button"]).button-primary,
    .ddk-container.ddk-container button:not(.btn):not(.edit-theme-button):not([type="button"]).button-primary,
    .ddk-container.ddk-container input[type="submit"].button-primary,
    .ddk-container.ddk-container input[type="reset"].button-primary,
    .ddk-container.ddk-container input[type="button"].button-primary {
      color: ${props => props.theme.background_content};
      background-color: ${props => props.theme.accent};
      border-color: ${props => props.theme.accent};
    }

    .ddk-container.ddk-container .button:not(.btn):not(.edit-theme-button):not([type="button"]).button-primary:hover:enabled,
    .ddk-container.ddk-container button:not(.btn):not(.edit-theme-button):not([type="button"]).button-primary:hover:enabled,
    .ddk-container.ddk-container input[type="submit"].button-primary:hover:enabled,
    .ddk-container.ddk-container input[type="reset"].button-primary:hover:enabled,
    .ddk-container.ddk-container input[type="button"].button-primary:hover:enabled,
    .ddk-container.ddk-container .button.button-primary:focus,
    .ddk-container.ddk-container button.button-primary:focus,
    .ddk-container.ddk-container input[type="submit"].button-primary:focus,
    .ddk-container.ddk-container input[type="reset"].button-primary:focus,
    .ddk-container.ddk-container input[type="button"].button-primary:focus {
      color: ${props => props.theme.colorway[1]};
      background-color: ${props => props.theme.background_content};
      border-color: ${props => props.theme.colorway[1]};
    }

    .ddk-container.ddk-container input:not([type]),
    .ddk-container.ddk-container input[type="email"],
    .ddk-container.ddk-container input[type="number"],
    .ddk-container.ddk-container input[type="search"],
    .ddk-container.ddk-container input[type="text"],
    .ddk-container.ddk-container input[type="tel"],
    .ddk-container.ddk-container input[type="url"],
    .ddk-container.ddk-container input[type="password"],
    .ddk-container.ddk-container textarea,
    .ddk-container.ddk-container select {
      background-color: ${props => props.theme.background_content};
      border-color: ${props => props.theme.border};
    }

    .ddk-container.ddk-container input[type="email"]:focus,
    .ddk-container.ddk-container input[type="number"]:focus,
    .ddk-container.ddk-container input[type="search"]:focus,
    .ddk-container.ddk-container input[type="text"]:focus,
    .ddk-container.ddk-container input[type="tel"]:focus,
    .ddk-container.ddk-container input[type="url"]:focus,
    .ddk-container.ddk-container input[type="password"]:focus,
    .ddk-container.ddk-container textarea:focus,
    .ddk-container.ddk-container select:focus,
    .ddk-container.ddk-container input[type="email"]:hover:enabled,
    .ddk-container.ddk-container input[type="number"]:hover:enabled,
    .ddk-container.ddk-container input[type="search"]:hover:enabled,
    .ddk-container.ddk-container input[type="text"]:hover:enabled,
    .ddk-container.ddk-container input[type="tel"]:hover:enabled,
    .ddk-container.ddk-container input[type="url"]:hover:enabled,
    .ddk-container.ddk-container input[type="password"]:hover:enabled,
    .ddk-container.ddk-container textarea:hover:enabled,
    .ddk-container.ddk-container select:hover:enabled {
      border-color: ${props => props.theme.accent}; }
      outline-color: 0;
    }

    .ddk-container.ddk-container th,
    .ddk-container.ddk-container td {
      border-bottom-color: ${props => props.theme.border};
    }

    .ddk-container.ddk-container hr {
      border-top-color: ${props => props.theme.border}; }

    /* CHECKBOXES and RADIO BUTTONS */

    .ddk-container.ddk-container input[type=checkbox],
    .ddk-container.ddk-container input[type=radio]   {
        border: 1px solid ${props => props.theme.border};
        color: ${props => props.theme.accent};
        width: 1em;
        height: 1em;
        outline: 0;
        padding: 0;
        float: left;
        -webkit-appearance: none;
        -moz-appearance: none;
        margin-right: 5px;
    }

    .ddk-container.ddk-container input[type=radio] {
        border-radius: 50%;
        position: relative;
    }

    .ddk-container.ddk-container input[type=radio]:checked:before {
        content: "";
        background-color: ${props => props.theme.accent};
        float: left;
        height: 100%;
        width: 100%;
        position: absolute;
        transform: scale(0.65);
    }

    .ddk-container.ddk-container input[type=checkbox]:checked:before {
        content: "✔";
        float: left;
        width: 1em;
        height: 1em;
        line-height: 1em;
        text-align: center;
    }

    .ddk-container.ddk-container input[type=radio]:checked:before {
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
    }

    /* Typography — Headers */
    .ddk-container.ddk-container h1,
    .ddk-container.ddk-container h2,
    .ddk-container.ddk-container h3,
    .ddk-container.ddk-container h4,
    .ddk-container.ddk-container h5,
    .ddk-container.ddk-container h6 {
        font-family: ${props => props.theme.font_family_headings};
        color: ${props => props.theme.heading_text};
    }

    /*
     * Fixes browser bug (?) with input placeholder text
     * not responding to element 'color'
     * from stackoverflow.com/a/2610741
     * these rules cannot be grouped
     */
    .ddk-container ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        color: ${props => props.theme.control_text} !important;
    }
    .ddk-container :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
       color: ${props => props.theme.control_text} !important;
       opacity:  1;
    }
    .ddk-container ::-moz-placeholder { /* Mozilla Firefox 19+ */
       color: ${props => props.theme.control_text} !important;
       opacity:  1;
    }
    .ddk-container :-ms-input-placeholder { /* Internet Explorer 10-11 */
       color: ${props => props.theme.control_text} !important;
    }
    .ddk-container ::-ms-input-placeholder { /* Microsoft Edge */
       color: ${props => props.theme.control_text} !important;
    }

    .ddk-container ::placeholder { /* Most modern browsers support this now. */
       color: ${props => props.theme.control_text} !important;
    }

    .ddk-container .layout-header ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        color: inherit !important;
    }
    .ddk-container .layout-header :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
       color: inherit !important;
       opacity:  1;
    }
    .ddk-container .layout-header ::-moz-placeholder { /* Mozilla Firefox 19+ */
       color: inherit !important;
       opacity:  1;
    }
    .ddk-container .layout-header :-ms-input-placeholder { /* Internet Explorer 10-11 */
       color: inherit !important;
    }
    .ddk-container .layout-header ::-ms-input-placeholder { /* Microsoft Edge */
       color: inherit !important;
    }

    .ddk-container .layout-header ::placeholder { /* Most modern browsers support this now. */
       color: inherit !important;
    }

    .ddk-container .layout-header:not([style*="color:"]) ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
         color: ${props => 
            (props.theme.header_text !== props.theme.text
            && props.theme.control_text === props.theme.text)
              ? 'inherit'
              : props.theme.control_text
        } !important;
    }
    .ddk-container .layout-header:not([style*="color:"]) :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: ${props => 
            (props.theme.header_text !== props.theme.text
            && props.theme.control_text === props.theme.text)
              ? 'inherit'
              : props.theme.control_text
        } !important;
       opacity:  1;
    }
    .ddk-container .layout-header:not([style*="color:"]) ::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: ${props => 
            (props.theme.header_text !== props.theme.text
            && props.theme.control_text === props.theme.text)
              ? 'inherit'
              : props.theme.control_text
        } !important;
       opacity:  1;
    }
    .ddk-container .layout-header:not([style*="color:"]) :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: ${props => 
            (props.theme.header_text !== props.theme.text
            && props.theme.control_text === props.theme.text)
              ? 'inherit'
              : props.theme.control_text
        } !important;
    }
    .ddk-container .layout-header:not([style*="color:"]) ::-ms-input-placeholder { /* Microsoft Edge */
        color: ${props => 
            (props.theme.header_text !== props.theme.text
            && props.theme.control_text === props.theme.text)
              ? 'inherit'
              : props.theme.control_text
        } !important;
    }
    .ddk-container .layout-header:not([style*="color:"]) ::placeholder {
        color: ${props => 
            (props.theme.header_text !== props.theme.text
            && props.theme.control_text === props.theme.text)
              ? 'inherit'
              : props.theme.control_text
        } !important;
    }

    /* Modal Close Button */

    .ddk-container.ddk-container .close-button {
      background-color: black;
      position: absolute;
      top: -15px;
      right: -15px;
      color: white;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      text-align: center;
      cursor: pointer;
      opacity: 60%;
      z-index: 1;
      line-height: 28px;
    }
`
export default themedStyles;
