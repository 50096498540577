import { css, objectToStyles } from '../utils.js';

const themedStyles = css`
    .ddk-container.ddk-container .tab.tab--selected,
    .ddk-container.ddk-container .tab {
        background-color: ${props => props.theme.background_content};
        color: ${props => props.theme.body_text};
    }
    .ddk-container.ddk-container .tab {
        border: 1px solid ${ props => props.theme.border}
        ${ props => objectToStyles(props.theme.border_style) };
    }
    .ddk-container.ddk-container .tab.tab--selected,
    .ddk-container.ddk-container .tab:last-of-type.tab--selected {
        border-top: 2px solid ${props => props.theme.accent};
        border-bottom: none;
    }
    .ddk-container.ddk-container .tab--selected:hover {
        background-color: ${props => props.theme.background_content};
    }
    .ddk-container.ddk-container .tab:last-of-type {
        border-right: 1px solid ${props => props.theme.border};
        border-bottom: 1px solid ${props => props.theme.border}
    }
    @media screen and (min-width: 800px) {
        .ddk-container:not(.ddk-container--print-context).ddk-container .tab {
            border: 1px solid ${ props => props.theme.border};
            ${ props => objectToStyles(props.theme.border_style) };
        }
        .ddk-container:not(.ddk-container--print-context).ddk-container .tab--selected,
        .ddk-container:not(.ddk-container--print-context).ddk-container .tab:last-of-type.tab--selected {
            border-top: 2px solid ${props => props.theme.accent};
        }
        .ddk-container:not(.ddk-container--print-context).ddk-container .tab-container--vert .tab {
            width: 100%;
        }
    }
`
export default themedStyles;
