import { css } from '../utils.js';
import tinycolor from 'tinycolor2'

const getContrastingColor = (baseColor, theme) => {
    const { background_content, background_page, text, accent } = theme
    return tinycolor.mostReadable(
        baseColor,
        [background_content, background_page, text, accent],
        /* returns black or white if none of the supplied
           theme colors are readable enough */
        {includeFallbackColors:true}
    ).toHexString();
}

// mutates CSS with no return value
const themedStyles = css`
        body .dash-debug-menu--closed {
            background-color: ${props => getContrastingColor(props.theme.background_page, props.theme)};
        }
        body .dash-debug-menu--closed:hover {
            background-color: ${props => tinycolor(getContrastingColor(props.theme.background_page, props.theme)).darken().toString()};
        }

        body .dash-debug-menu--open {
            box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25),
                0px 1px 3px ${props => props.theme.border};
            background-color: ${props => getContrastingColor(props.theme.background_content, props.theme)};
        }
        body .dash-debug-menu--open:hover {
            background-color: ${props => tinycolor(getContrastingColor(props.theme.background_content, props.theme)).darken().toString()};
        }

        body .dash-debug-menu__button-label {
            color: {props => props.theme.border};
        }

        body .dash-debug-menu__button {
            background-color: ${props => getContrastingColor('#ffffff', props.theme)};
            border: 1px solid ${props => props.theme.border};
            color: ${props => getContrastingColor(getContrastingColor('#ffffff', props.theme), props.theme)};
        }
        body .dash-debug-menu__button:hover {
            background-color: ${props => tinycolor(getContrastingColor('#ffffff', props.theme)).darken().toString()};
        }

        body .dash-debug-menu__button.dash-debug-menu__button--enabled {
            background-color: ${props => props.theme.accent_positive};
            color: ${props => getContrastingColor(props.theme.accent_positive, props.theme)};
        }
        body .dash-debug-menu__button.dash-debug-menu__button--enabled:hover {
            background-color: ${props => tinycolor(props.theme.accent_positive).darken().toString()};
        }

        body svg.dash-debug-menu__icon.dash-debug-menu__icon--debug path {
            fill: ${props => getContrastingColor(getContrastingColor(props.theme.background_page, props.theme), props.theme)};
        }

        body .dash-debug-menu__button.dash-debug-menu__button--available {
            background-color: ${props => props.theme.accent_positive};
            color: ${props => getContrastingColor(props.theme.accent_positive, props.theme)};
        }
        body .dash-debug-menu__button.dash-debug-menu__button--available:hover {
            background-color: ${props => tinycolor(props.theme.accent_positive,).darken().toString()};
        }

        body .dash-debug-menu__button.dash-debug-menu__button--unavailable {
            background-color: ${props => props.theme.accent_negative};
            color: ${props => getContrastingColor(props.theme.accent_negative, props.theme)};
        }
        body .dash-debug-menu__button.dash-debug-menu__button--unavailable:hover {
            background-color: ${props => tinycolor(props.theme.accent_negative).darken().toString()};
        }

        body .dash-debug-menu__button.dash-debug-menu__button--cold {
            /*
             * Here, this yellow has a UI meaning that isn't reliably
             * a var in the theme definition, so it's a constant
             */
            background-color: #FDDA68;
            color: white;
        }
        body .dash-debug-menu__button.dash-debug-menu__button--cold:hover {
            /* Lightened "cold reload yellow" — darkened is consistent but unappealing */
            background-color: #FEECB3;
        }
`

export default themedStyles;
