import React from 'react';

export default () =>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84.62 96.62">
        <title>Copy text</title>
        <g id="Layer_2" dataName="Layer 2">
            <g id="Layer_1-2" dataName="Layer 1">
                <path className="cls-3" d="M56.07,96.62H10.64A10.63,10.63,0,0,1,0,86V22.75A10.63,10.63,0,0,1,10.64,12.16h8.52v5.08H10.64a5.48,5.48,0,0,0-5.42,5.52V86a5.48,5.48,0,0,0,5.42,5.52H56.07A5.48,5.48,0,0,0,61.48,86V82.25h5.22V86A10.63,10.63,0,0,1,56.07,96.62Z"/>
                <path className="cls-1" d="M75.4,84.29H23.69a9.23,9.23,0,0,1-9.22-9.22V9.22A9.23,9.23,0,0,1,23.69,0H64.81L84.62,22.64V75.07A9.23,9.23,0,0,1,75.4,84.29ZM23.69,5a4.23,4.23,0,0,0-4.22,4.22V75.07a4.23,4.23,0,0,0,4.22,4.22H75.4a4.23,4.23,0,0,0,4.22-4.22V24.52L62.54,5Z"/>
                <path className="cls-1" d="M82.12,28.55H71.38c-10.47,0-13.24-10.1-13.24-15.44V2.5h5V13.11c.06,10.15,7.41,10.45,8.24,10.45H82.12Z"/>
                <path className="cls-2" d="M31.34,48.34H65.95c4,0,4-6.2,0-6.2H31.34c-4,0-4,6.2,0,6.2Z"/>
                <path className="cls-2" d="M30.74,63.44H65.35c4,0,4-6.2,0-6.2H30.74c-4,0-4,6.2,0,6.2Z"/>
            </g>
        </g>
    </svg>
