import { css } from '../utils.js';
import chroma from 'chroma-js';

const themedStyles = css`
    .rc-slider {
        margin-left: 5px; /* otherwise the slide handle overflows its container */
    }
    .ddk-container .rc-slider:not(.rc-slider-vertical) {
        width: calc(100% - 10px); /* the native CSS has margin 5px on each side = 10px */
    }
    .rc-slider.rc-slider-with-marks {
        /* height of rail + height of marks */
        min-height: 36px;
    }
    .ddk-container .rc-slider-vertical, .rc-slider-vertical.rc-slider-with-marks {
        min-height: 100px;
    }

    .ddk-container .control.control--vertical.label--top .rc-slider-vertical.rc-slider-with-marks {
        margin-bottom: 10px;
    }
    .ddk-container .control.control--vertical.label--top .rc-slider-vertical.rc-slider-with-marks
    .rc-slider-mark {
        margin-top: 10px;
    }
    .ddk-container .layout-header .rc-slider-handle {
        background-color: ${props => props.theme.header_background_color};
    }

`
export default themedStyles;
